import { crearMetodoPago } from "./stripe"

export const MESES = [
    { id: 1, text: '01' },
    { id: 2, text: '02' },
    { id: 3, text: '03' },
    { id: 4, text: '04' },
    { id: 5, text: '05' },
    { id: 6, text: '06' },
    { id: 7, text: '07' },
    { id: 8, text: '08' },
    { id: 9, text: '09' },
    { id: 10, text: '10' },
    { id: 11, text: '11' },
    { id: 12, text: '12' },
]

export const obtenerAniosVencimiento = (aniosFuturos = 10) => {
    let fecha = new Date()
    let anios = []

    anios.push({ id: fecha.getFullYear(), text: String(fecha.getFullYear()) })
    for(let i = 1; i <= aniosFuturos; i++) {
        anios.push({ id: fecha.getFullYear()+i, text: String(fecha.getFullYear()+i) })
    }
    return anios
}

export const crearMetodoPagoStripe = async (clienteStripeID = null, infoTarjeta = null, usuario = null) => {
    if(clienteStripeID && infoTarjeta) {
        let data = {
            clienteID: clienteStripeID,
            tipo: 'card',
            mesExpiracion: infoTarjeta.mes,
            anioExpiracion: infoTarjeta.anio,
            numeroTarjeta: infoTarjeta.numero,
            cvc: infoTarjeta.cvv,
            nombre: usuario?.nombre,
            email: usuario?.correo,
        }
        return await crearMetodoPago(data)
    }
    else return {
        ok: false,
        mensaje: 'No hay información para comprobar el pago'
    }
}