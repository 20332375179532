import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_EMISOR = 'EurekaPaCuentaFiscal'
const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'
const TABLA_CONTACTO = 'EurekaBsContacto'

export const emisorAPI = createApi({
    reducerPath: 'emisorAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Emisores'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerEmisor: builder.query({
            /**
             * 
             * @param {{ id: int }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_EMISOR)
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.where('id', data?.id).first()
                }
            }
        }),
        crearEmisor: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                // console.log('aquiii')
                // console.log(token())
                
                let body = new EkQuery(TABLA_EMISOR)
                if(data.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        guardarLogo: builder.mutation({
            /**
             * @param {{ id: number, configFile: { file: object, carpeta: string, nombre: string }  }} data
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        editarArchivo: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                if(data.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerArchivo: builder.mutation({
            /**
             * 
             * @param {{ id: int }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA).where('id', data.id).first()

                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        guardarCertificado: builder.mutation({
            /**
             * @param {{ id: number, configFile: { file: object, carpeta: string, nombre: string }  }} data
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        guardarIdentificacion: builder.mutation({
            /**
             * @param {{ id: number, configFile: { file: object, carpeta: string, nombre: string }  }} data
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        crearContactoComision: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CONTACTO)
                if(data.id) body = body.where('id', data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        obtenerContactosExistentes: builder.query({
            /**
             * 
             * @param {{ usuarioID: int }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CONTACTO)
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.where('contacto', data?.usuarioID).get()
                }
            }
        }),
    }),
})


export const { 
    useObtenerEmisorQuery,
    useCrearEmisorMutation,
    useGuardarLogoMutation,
    useEditarArchivoMutation,
    useObtenerArchivoMutation,
    useGuardarCertificadoMutation,
    useGuardarIdentificacionMutation,
    useCrearContactoComisionMutation,
    useObtenerContactosExistentesQuery,
} = emisorAPI