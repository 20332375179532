import { Input, Select, Formulario } from 'eureka-design'
import { useState } from 'react'
import { numberFormat } from '../../js/util'
import { obtenerValor } from '../../js/factura'
import { RECURRENCIAS, ILIMITADA, INACTIVA, ACTIVA, cambiarEstatusSubscripcion } from '../../js/subscripcion'
import { useCrearSubscripcionMutation } from '../../services/subcripcion'
import { verificarNombre } from '../../js/membresia'

export const FORM_SUBSCRIPCION = 'form_subscripcion'

const FormSubscripcion = ({ monedas = [], form_subscripcion = {}, recurrenciasUtilizadas = [], UpProps, ShowAlert }) => {
    const [subID, fijaSubID] = useState(form_subscripcion?.id)
    const [tokenStripe, fijaTokenStripe] = useState(form_subscripcion?.token_stripe)

    const [crearSubscripcionMutation, {
        isLoading,
        isError,
        isSuccess
    }] = useCrearSubscripcionMutation()

    const handlerCrearSubscripcion = async (col, val) => {
        return await crearSubscripcionMutation({
            id: subID,
            body: {
                [col]: val,
                membresia: form_subscripcion.membresia
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaSubID(id)
                return id
            }
            else {
                return subID
            }
        })
    }

    const handlerInput = async (val, nom) => {
        if(form_subscripcion?.token_stripe) {
            ShowAlert({
                icono: 'warning',
                titulo: 'Permiso Denegado',
                mensaje: 'La subscripción ya fue activada, no puedes editar la información para evitar errores con los pagos. Intente desactivarla si realmente ya no la necesita.',
            })
        }
        else {
            let precio = 0

            val = (val instanceof Object) ? val.id : val
            if(nom === 'precio') {
                precio = parseFloat(val)
                if(isNaN(precio)) {
                    ShowAlert({
                        icono: 'error',
                        titulo: 'Formato Incorrecto',
                        mensaje: 'Ingrese un precio correcto para la membresía',
                        time: 2,
                    })
                    return
                }
                else val = precio
            }
            else if(nom === 'recurrencia') {
                if(recurrenciasUtilizadas.includes(val)) {
                    ShowAlert({
                        icono: 'error',
                        titulo: 'Recurrencia No Permitida',
                        mensaje: 'La recurrencia elegida ya esta utiliza en otra subscripción activa.',
                        time: 3
                    })
                    return
                }
            }
            else val = nom === 'nombre' ? verificarNombre(val) : val

            let id = null
            if(subID) {
                id = subID
                handlerCrearSubscripcion(nom, val)
            }
            else {
                id = await handlerCrearSubscripcion(nom, val)
            }
            handlerState(nom, val, id)
        }
    }

    const handlerState = (nom = '', val = '', id = null, extra = {}) => {
        UpProps({ 
            [FORM_SUBSCRIPCION]: {
                ...form_subscripcion,
                id: id ? id : subID, 
                [nom]: val,
                ...extra,
            }
        })
    }

    const infoCompletaSubscripcion = () => {
        return form_subscripcion?.nombre  &&
               form_subscripcion?.precio &&
               form_subscripcion?.recurrencia &&
               form_subscripcion?.moneda
    }

    const handlerSubscripcionStripe = async (estatus = null) => {
        if(!infoCompletaSubscripcion()) {
            ShowAlert({
                icono: 'error',
                titulo: 'Activación No Posible',
                mensaje: 'Complete toda la información de la subscripción para activarla.',
                time: 2,
            })
        }
        else {
            let res = await cambiarEstatusSubscripcion(estatus, form_subscripcion, form_subscripcion.token_stripe_producto)
            // console.log(res)
            if(res.ok) {
                let plan = res.planes[0]
                fijaTokenStripe(plan.id)
                handlerInput(plan.id, 'token_stripe')

                ShowAlert({
                    icono: 'success',
                    titulo: `Subscrición ${estatus === ACTIVA ? 'Activada' : 'Archivada'}`,
                    mensaje: 'Ahora la subscripción esta disponible para los usuarios.',
                    time: 2,
                })
            }
            else {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error Interno',
                    mensaje: res?.mensaje,
                    time: 2,
                })
            }
        }
    }

    return (
        <Formulario
            titulo={`Crear Subscripción`}
            isLoading={[isLoading]}
            isError={[isError]}
            isSuccess={[isSuccess]}
            botones={[
                form_subscripcion?.token_stripe ? 
                {
                    texto: 'Desactivar Subscripción',
                    onClick: () => { handlerSubscripcionStripe(INACTIVA) },
                    iconLeft: "fa-gear",
                } :
                {
                    texto: 'Activar Subscripción',
                    onClick: () => { handlerSubscripcionStripe(ACTIVA) },
                    iconLeft: "fa-gear",
                }
            ]}
        >
            <Input 
                nombre="nombre"
                placeholder="Nombre"
                iconLeft="fa-signature"
                value={form_subscripcion?.nombre}
                changeFunction={handlerInput}
            />
            <Input 
                nombre="precio"
                placeholder="Precio"
                iconLeft="fa-dollar-sign"
                value={form_subscripcion?.precio ? numberFormat(parseFloat(form_subscripcion.precio), 2, '.', ',', '') : ''}
                changeFunction={handlerInput}
                disabled={tokenStripe || form_subscripcion?.recurrencia === ILIMITADA ? true : false}
            />
            <Select 
                nombre="moneda"
                busqueda={{ placeholder: 'Moneda' }}
                iconLeft="fa-coins"
                options={monedas}
                value={obtenerValor(monedas, form_subscripcion?.moneda, 'Seleccione una moneda...')}
                changeFunction={handlerInput}
                disabled={tokenStripe ? true : false}
            />
            <Select 
                nombre="recurrencia"
                placeholder="Recurrencia"
                iconLeft="fa-repeat"
                options={RECURRENCIAS}
                value={obtenerValor(RECURRENCIAS, form_subscripcion?.recurrencia, 'Seleccione una recurrencia...')}
                changeFunction={handlerInput}
                disabled={tokenStripe ? true : false}
            />
        </Formulario>
    )
}

export default FormSubscripcion