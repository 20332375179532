import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useObtenerUsuariosQuery } from '../../services/usuario'
import { capitalize } from '../../js/util'
import { FORM_USUARIO } from './EditarUsuario'
import { obtenerSubscripcion } from '../../js/subscripcion'
import ModalArchivar from '../../components/usuarios/ModalArchivar'
import { useState } from 'react'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { handler_rol } from '../../js/handler_rol'
import { ARCHIVAR_USUARIOS, CREAR_USUARIOS, EDITAR_USUARIOS, obtenerRol } from '../../js/roles'

const Usuarios = ({ ShowAlert, Push, roles }) => {
    const [getPermiso] = handler_rol(roles)

    const [mostrarModalArchivar, fijaMostrarModal] = useState(false)
    const [usuarioSeleccionado, fijaUsuarioSeleccionado] = useState(null)

    const { data, isLoading, refetch } = useObtenerUsuariosQuery({ usuarioID: infoUsuario().id })

    if(isLoading) return
    else {
        return (
            <>
                <ModalArchivar
                    alert={ShowAlert}
                    mostrarModal={mostrarModalArchivar}
                    cambiarEstadoModal={fijaMostrarModal}
                    usuario={usuarioSeleccionado}
                    actualizarUsuarios={refetch}
                />

                <Tabla
                    checkbox={false}
                    noData="Aún no hay usuarios para mostrar"
                    busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                    headers={[
                        { key: 'nombre',    text: 'Nombre',    type: 'text' },
                        { key: 'rol',       text: 'Rol',       type: 'text' },
                        { key: 'membresia', text: 'Membresia', type: 'text' },
                        { key: 'estatus',   text: 'Estatus',   type: 'text' },
                    ]}
                    botonesHead={[
                        {
                            icono: 'fa-solid fa-plus',
                            tooltip: 'Agregar',
                            onClick: () => {
                                if(getPermiso(CREAR_USUARIOS))
                                    Push('form_usuario', {
                                        [FORM_USUARIO]: { id : null }
                                    })
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Permiso Denegado',
                                        mensaje: 'Usted no cuenta con los permisos necesarios para crear usuarios',
                                    })
                                }
                            }
                        },
                    ]}
                    data={
                        buildData(data ?? [], [
                            { key: 'nombre', type: 'text' },
                            { 
                                key: 'rol', 
                                type: 'text',
                                text: (item) => capitalize(obtenerRol(item?.eureka_cr_rol_usuarios)),
                            },
                            { 
                                key: 'membresia',
                                type: 'text',
                                text: (item) => {
                                    let subscripcion = obtenerSubscripcion(item.eureka_pa_subscriptores)
                                    return subscripcion ? subscripcion.eureka_pa_subscripcione.nombre : 'Ninguna'
                                },
                                value: (item) => {
                                    let subscripcion = obtenerSubscripcion(item.eureka_pa_subscriptores)
                                    return subscripcion > 0 ? subscripcion.eureka_pa_subscripcione.nombre : 'Ninguna'
                                }
                            },
                            { 
                                key: 'estatus', 
                                type: 'text',
                                text: (item) => {
                                    if(item.estatus === 'inactivo') {
                                        return <ChipEstatus tipo="Error" texto="Inactivo" />
                                    }
                                    else if(item.estatus === 'bloqueado') {
                                        return <ChipEstatus tipo="Error" texto="Archivado" />
                                    }
                                    else {
                                        return <ChipEstatus tipo="Aceptado" texto="Activo" />
                                    }
                                },
                                value: (item) => item.estatus
                            },
                        ])
                    }
                    onClickRow={() => {}}
                    botonesRow={[
                        {
                            icono: 'fa-solid fa-pen',
                            tooltip: 'Editar',
                            onClick: (item) => {
                                if(getPermiso(EDITAR_USUARIOS)) {
                                    let subscripciones = obtenerSubscripcion(item.item.eureka_pa_subscriptores, 'activo', true)
                                    subscripciones = subscripciones.filter((sub) => sub.token_stripe === null && sub.subscripcion === 1)
                                    let subscripcion = subscripciones.length > 0 ? subscripciones[0] : null
    
                                    Push('form_usuario', {
                                        [FORM_USUARIO]: { 
                                            ...item.item,
                                            subscriptorID: subscripcion?.id,
                                            membresia: subscripcion ? 'SI' : 'NO'
                                        }
                                    })
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Permiso Denegado',
                                        mensaje: 'Usted no cuenta con los permisos necesarios para editar usuarios',
                                    })
                                }
                            }
                        },
                        {
                            icono: 'fa-solid fa-trash',
                            tooltip: 'Archivar',
                            onClick: (item) => {
                                if(getPermiso(ARCHIVAR_USUARIOS)) {
                                    let usuario = item.item
                                    if(usuario.estatus === 'activo') {
                                        fijaMostrarModal(true)
                                        fijaUsuarioSeleccionado(usuario)
                                    }
                                    else {
                                        ShowAlert({
                                            icono: 'error',
                                            titulo: 'Error al Archivar',
                                            mensaje: 'El usuario ya ha sido archivado anteriormente',
                                            time: 3,
                                        })
                                    }
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Permiso Denegado',
                                        mensaje: 'Usted no cuenta con los permisos necesarios para archivar usuarios',
                                    })
                                }
                            }
                        },
                    ]}
                />
            </>
        )
    }
}

export default Usuarios