import { Select } from 'eureka-design'
import { obtenerValor } from '../../js/factura'

const SeccionComprobante = ({ 
    titulo = '',
    tiposRelaciones = [], 
    monedas = [], 
    formasPagos = [], 
    metodosPagos = [], 
    onChangeEntrada = () => {},
    formFactura = {},
    receptor = {},
}) => {
    const estiloTextoCentrado = { textAlign: 'center', margin: '15px 0' }

    return (
        <>
            <h3 style={estiloTextoCentrado}>{ titulo }</h3>
            <Select 
                nombre="tipo_relacion"
                iconLeft="fa-file-invoice"
                options={tiposRelaciones}
                value={obtenerValor(tiposRelaciones, formFactura?.tipo_relacion, 'Seleccione un tipo de relación...')}
                changeFunction={onChangeEntrada}
                busqueda={{ placeholder: 'Tipo de Relación' }}
            />
            <Select 
                nombre="moneda"
                iconLeft="fa-circle-dollar-to-slot"
                options={monedas}
                value={obtenerValor(monedas, formFactura?.moneda ?? receptor?.moneda, 'Seleccione una moneda...')}
                changeFunction={onChangeEntrada}
                busqueda={{ placeholder: 'Moneda' }}
            />
            <Select 
                nombre="forma_pago"
                iconLeft="fa-money-check-dollar"
                options={formasPagos}
                value={obtenerValor(formasPagos, formFactura?.forma_pago ?? receptor?.forma_pago, 'Seleccione una forma de pago...')}
                changeFunction={onChangeEntrada}
                busqueda={{ placeholder: 'Forma de Pago' }}
            />
            <Select 
                placeholder="Método de Pago"
                nombre="metodo_pago"
                iconLeft="fa-hand-holding-dollar"
                options={metodosPagos}
                value={obtenerValor(metodosPagos, formFactura?.metodo_pago, 'Seleccione un método de pago...')}
                changeFunction={onChangeEntrada}
            />
        </>
    )
}

export default SeccionComprobante