import { useEffect, useState } from 'react'
import Index from './layouts/index'
import { config } from '../config/config'
import { CargandoPagina } from 'eureka-design'
import {
    crearCookie,
    configurarVerificacionSesion,
    iniciarProcesoRefrescarToken,
    token,
    usuario as infoUsuario,
 } from 'account-react-eurekasigma'
import { useCrearEmisorMutation } from '../services/emisor'
import { useSelector } from 'react-redux'
import { iniciarVerificacionSubscripcion } from '../js/subscripcion'

const Inicio = () => {
    const parametrosURL = new URLSearchParams(window.location.search)
    let distribuidorID = parametrosURL.get('invitacion')
    if(distribuidorID) {
        localStorage.removeItem('invitacion')
        localStorage.setItem('invitacion', decodeURIComponent(distribuidorID))
    }
    // else no existe invitación

    const sesionSlice = useSelector(state => state.sesionSlice)

    const [verificandoSesion, cambiarEstadoVerificarSesion] = useState(true)
    const [mensajePantallaCarga, fijaMensajePantallaCarga] = useState('Procesando credenciales, por favor espere...')

    const [crearEmisor] = useCrearEmisorMutation()
    
    useEffect(() => {
        /**
         * 
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const verificarDatosFiscales = async (usuario = {}, tokenSesion = '') => {
            if(usuario.eureka_pa_cuenta_fiscals.length === 0) {
                usuario = await crearEmisor({
                    body: { usuario: usuario.id }
                })
                .unwrap()
                .then((res) => {
                    return {
                        ...usuario,
                        eureka_pa_cuenta_fiscals: [{ id: res.mensaje.id }]
                    }
                })
            }
            else {
                let usuarioFiscal = usuario.eureka_pa_cuenta_fiscals[0]
                usuario = {
                    ...usuario,
                    eureka_pa_cuenta_fiscals: [{ id: usuarioFiscal.id }]
                }
            }
            guardarDatosSesion(usuario, tokenSesion)
            iniciarProcesoRefrescarToken(
                usuario, 
                tokenSesion,
                config.tiempoRefrescar,
                guardarDatosSesion,
                false,
                config.inactividad,
                `${window.location.origin}/cerrar_sesion`
            )
        }

        /**
         * 
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const guardarDatosSesion = (usuario = {}, tokenSesion = '') => {
            crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
            crearCookie(config.nomCookieToken, tokenSesion, true, config.expCookie)
        }

        if(verificandoSesion) {
            iniciarVerificacionSubscripcion(sesionSlice?.user?.id ?? infoUsuario()?.id)
            cambiarEstadoVerificarSesion(false)
            console.log(token())

            configurarVerificacionSesion(
                sesionSlice,
                config.nomCookieUsuario,
                config.nomCookieToken,
                `${window.location.origin}/cerrar_sesion`,
                verificarDatosFiscales
            )
        }
        else {
            fijaMensajePantallaCarga('Redirigiendo, por favor espere...')
        }
    }, [verificandoSesion])

    if(verificandoSesion) 
        return <CargandoPagina 
                    mensaje={mensajePantallaCarga} 
                    colorPrincipal={config.colorPrincipal} 
                />
    else
        return <Index />
}

export default Inicio