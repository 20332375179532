import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_FACTURA = 'EurekaFaFactura'
const TABLA_CONCEPTOS = 'EurekaFaConcepto'
const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'

export const facturaAPI = createApi({
    reducerPath: 'facturaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Facturas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerFacturas: builder.query({
            /**
             * 
             * @param {{ usuarioID: int }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_FACTURA)
                            .where('usuario_emisor', '=', data.usuarioID)
                            .with('eureka_pa_cuenta_fiscal')
                            .with('eureka_fa_conceptos.eureka_st_producto')
                            .with('eureka_cr_multimedia')
                            .with('eureka_cr_multimedia_pdf')
                            .get()
                }
            },
        }),
        crearFactura: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_FACTURA)
                if (data?.id) {
                    body = body.where("id", data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearConceptoFactura: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CONCEPTOS)
                if (data?.id) {
                    body = body.where("id", data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        editarArchivoFactura: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                if (data?.id) {
                    body = body.where("id", data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearArchivoFactura: builder.mutation({
            /**
             * @param {{ id: number, configFile: { file: object, carpeta: string, nombre: string }  }} data
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
    }),
})

export const {
    useCrearFacturaMutation,
    useObtenerFacturasQuery,
    useCrearConceptoFacturaMutation,
    useEditarArchivoFacturaMutation,
    useCrearArchivoFacturaMutation,
} = facturaAPI