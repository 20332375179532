import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useObtenerFacturasQuery } from '../../services/factura'
import { FORM_FACTURA } from './GenerarFactura'
import { abrirArchivo, obtenerURLObjeto } from '../../js/factura'
import { config } from '../../config/config'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { useState } from 'react'
import ModalCancelar from '../../components/facturas/ModalCancelar'

const PaginaFacturas = ({ Push, ShowAlert, motivosCancelacion }) => {
    const { data, isLoading, refetch } = useObtenerFacturasQuery({ usuarioID: infoUsuario()?.id })

    const [mostrarModal, cambiarEstadoMostrarModal] = useState(false)
    const [UUID, fijaUUID] = useState('')
    const [facturaID, fijaFacturaID] = useState(null)
    const [pdfFacturaID, fijaPdfFacturaID] = useState(null)

    if(isLoading) return
    else {
        return (
            <>
                <ModalCancelar 
                    ShowAlert={ShowAlert}
                    mostrarModal={mostrarModal}
                    cambiarEstadoMostrarModal={cambiarEstadoMostrarModal}
                    UUID={UUID}
                    facturaID={facturaID}
                    motivosCancelacion={motivosCancelacion}
                    actualizarFacturar={refetch}
                    pdfFacturaID={pdfFacturaID}
                />
                
                <Tabla
                    checkbox={false}
                    noData="Aún no haz generado facturas"
                    busqueda={{ placeholder: 'Buscar...', keys: ['nombre'] }}
                    headers={[
                        { key: 'uuid',          text: 'UUID',              type: 'text' },
                        { key: 'fecha_creada',  text: 'Fecha de Creación', type: 'date' },
                        { key: 'fecha_emitida', text: 'Fecha de Emisión',  type: 'date' },
                        { key: 'cliente',       text: 'Receptor',          type: 'text' },
                        { key: 'estatus',       text: 'Estatus',           type: 'text' },
                    ]}
                    botonesHead={[
                        {
                            icono: 'fa-solid fa-plus',
                            tooltip: 'Generar',
                            onClick: (item) => {
                                Push('generar_factura', {
                                    [FORM_FACTURA]: { id: null }
                                })
                            }
                        }
                    ]}
                    data={
                        buildData(data ?? [], [
                            { key: 'uuid',          type: 'text' },
                            { key: 'fecha_creada',  type: 'date' },
                            { key: 'fecha_emitida', type: 'date' },
                            { 
                                key: 'cliente',
                                type: 'text',
                                text: (item) => {
                                    let receptor = item?.eureka_pa_cuenta_fiscal
                                    if(receptor) return receptor.razonsocial
                                    else return 'Sin cliente'
                                }
                            },
                            {
                                key: 'estaus',
                                type: 'text',
                                text: (item) => {
                                    if(item.estatus === 'programada') {
                                        return <ChipEstatus tipo="Pendiente" texto="Pendiente" />
                                    }
                                    else if(item.estatus === 'emitida') {
                                        return <ChipEstatus tipo="Pendiente" texto="Nesesario Corregir" />
                                    }
                                    else if(item.estatus === 'cancelado') {
                                        return <ChipEstatus tipo="Error" texto="Cancelada" />
                                    }
                                    else {
                                        return <ChipEstatus tipo="Aceptado" texto="Timbrado" />
                                    }
                                },
                                value: (item) => item.estatus
                            },
                        ])
                    }
                    onClickRow={(item) => {
                        if(item.item.estatus === 'programada' || item.item.estatus === 'emitida') {
                            Push('generar_factura', {
                                [FORM_FACTURA]: { ...item.item }
                            })
                        }
                        else {
                            ShowAlert({
                                icono: 'error',
                                titulo: 'Factura Timbrada',
                                mensaje: 'La factura solicitada ya a sido timbrada, por lo cual no es posible editar',
                                time: 5,
                            })
                        }
                    }}
                    botonesRow={[
                        {
                            icono: 'fa-solid fa-file-pdf',
                            tooltip: 'Descargar PDF',
                            onClick: async (item) => {
                                let pdf = item.item.eureka_cr_multimedia_pdf

                                if(pdf) {
                                    abrirArchivo(
                                        await obtenerURLObjeto(pdf.archivo), 
                                        'descargar', 
                                        item.item.estatus === 'timbrada' ? 'factura.pdf' : 'acuse_cancelacion.pdf'
                                    )
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Factura NO Timbrada',
                                        mensaje: 'Para poder descargar los archivos, primero debe realizar el proceso de timbrado',
                                        time: 5,
                                    })
                                }
                            }
                        },
                        {
                            icono: 'fa-solid fa-file-excel',
                            tooltip: 'Descargar XML',
                            onClick: async (item) => {
                                let xml = item.item.eureka_cr_multimedia

                                if(xml) {
                                    abrirArchivo(await obtenerURLObjeto(`${config.urlCRUFDEKArchivo}/${xml.carpeta}/${xml.nombre}`), 'descargar', 'cfdi.xml')
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Factura NO Timbrada',
                                        mensaje: 'Para poder descargar los archivos, primero debe realizar el proceso de timbrado',
                                        time: 5,
                                    })
                                }
                            }
                        },
                        {
                            icono: 'fa-solid fa-file-circle-xmark',
                            tooltip: 'Cancelar',
                            onClick: (item) => {
                                if(item.item.uuid !== null && item.item.estatus === 'timbrada') {
                                    fijaFacturaID(item.id)
                                    fijaUUID(item.item.uuid)
                                    fijaPdfFacturaID(item.item.pdf)
                                    cambiarEstadoMostrarModal(true)
                                }
                                else {
                                    ShowAlert({
                                        icono: 'error',
                                        titulo: 'Error al Cancelar',
                                        mensaje: 'La factura no puede ser cancelada ya que no se ha timbrado o porque ya fue cancelada',
                                        time: 3,
                                    })
                                }
                            }
                        }
                    ]}
                >
                </Tabla>
            </>
        )
    }
}

export default PaginaFacturas