import { Formulario, Input, Select } from 'eureka-design'
import { useEffect, useState } from 'react'
import { obtenerValor } from '../../js/factura'
import { useCrearReceptorMutation } from '../../services/receptor'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

export const FORM_CLIENTE = 'cliente'

const FormCliente = ({ UpTittle, UpProps, cliente, monedas, regimenesSociales, formasPagos }) => {
    const usuario = infoUsuario()
    const [clienteID, fijaClienteID] = useState(cliente?.id)

    const [crearReceptor, {
        isLoading,
        isSuccess,
        isError,
    }] = useCrearReceptorMutation()

    useEffect(() => {
        UpTittle(`Cliente ${cliente?.razonsocial ?? ''}`)
        // console.log(cliente)
    }, [UpTittle, cliente])

    const crearCliente = async (nom, val) => {
        return crearReceptor({
            id: clienteID,
            body: {
                estatus: 'aprobada',
                usuario: usuario.id,
                [nom]: val
            }
        })
        .unwrap()
        .then((resp) => {
            if(resp?.mensaje?.id) {
                let creadoID = resp.mensaje.id

                handlerFormData(nom, val, creadoID)
                fijaClienteID(creadoID)
                return creadoID
            }
            else return clienteID
        })
    }

    const cambiarEntrada = async (val, nom) => {
        crearCliente(nom, val)
        if(clienteID || cliente?.id) handlerFormData(nom, val)

        UpTittle(`Cliente ${cliente?.razonsocial ?? ''}`)
    }

    const cambiarSelector = async (obj, nom) => {
        let id = await crearCliente(nom, obj.id)
        handlerFormData(nom, obj.id, id)
    }

    const handlerFormData = (key, val, id = null) => {
        id = id === null ? clienteID : id

        UpProps({ 
            [FORM_CLIENTE]: {
                ...cliente,
                id: id, 
                [key]: val 
            }
        })
    }

    return (
        <Formulario
            titulo="Cliente"
            isLoading={[isLoading]}
            isSuccess={[isSuccess]}
            isError={[isError]}
        >
            <Input 
                type="text"
                nombre="idfiscal"
                placeholder="RFC"
                changeFunction={cambiarEntrada}
                value={cliente?.idfiscal}
                iconLeft="fa-id-card"
            />
            <Input 
                type="text"
                nombre="razonsocial"
                placeholder="Nombre o Razón Social"
                changeFunction={cambiarEntrada}
                value={cliente?.razonsocial}
                iconLeft="fa-user"
            />
            <Select
                iconLeft="fa-user-tie"
                options={regimenesSociales}
                value={obtenerValor(regimenesSociales, cliente?.regimen, 'Seleccione un régimen social...')}
                nombre="regimen"
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'Régimen Social' }}
            />
            <Input 
                type="text"
                nombre="codigopostal"
                placeholder="Domicilio Fiscal"
                changeFunction={cambiarEntrada}
                value={cliente?.codigopostal}
                iconLeft="fa-house-user"
            />
            <Select
                iconLeft="fa-circle-dollar-to-slot"
                options={monedas}
                value={obtenerValor(monedas, cliente?.moneda, 'Seleccione una moneda...')}
                nombre="moneda"
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'Moneda' }}
            />
            <Select 
                nombre="forma_pago"
                iconLeft="fa-money-check-dollar"
                options={formasPagos}
                value={obtenerValor(formasPagos, cliente?.forma_pago, 'Seleccione una forma de pago...')}
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'Forma de Pago' }}
            />
        </Formulario>
    )
}

export default FormCliente