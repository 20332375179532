import { InputSearch, Select, Input } from 'eureka-design'
import { useEffect, useState } from 'react'
import { obtenerValor } from '../../js/factura'

const SeccionReceptor = ({ 
    titulo = '', 
    receptores = [], 
    onChangeReceptor = () => {}, 
    usosFactura = [], 
    regimenSociales = [], 
    onChangeEntrada = () => {},
    receptor = {},
    formFactura = {}
}) => {
    const estiloTextoCentrado = { 
        textAlign: 'center',
        margin: '15px 0'
    }
    const [opciones, fijaOpciones] = useState([])

    useEffect(() => {
        fijaOpciones(adaptarOpciones(receptores))
    }, [receptores])

    /**
     * 
     * @param {Array} receptores 
     * @returns 
     */
    const adaptarOpciones = (receptores = []) => {
        if(receptores.length === 0) return []
        else {
            return receptores.map((receptor, indice) => {
                return { 
                    value: { 
                        ...receptor,
                        indice: indice
                    }, 
                    text: receptor.razonsocial 
                }
            })
        }
    }

    /**
     * 
     * @param {string} val 
     * @param {string} nom 
     */
    const buscarReceptor = (val = '', nom = '') => {
        if(val.length <= 2) {
            fijaOpciones(adaptarOpciones(receptores))
        }
        else {
            fijaOpciones(
                opciones.filter((opcion) => {
                    return val !== '' ? opcion.text.toUpperCase().includes(val.toUpperCase()) : true
                })
            )
        }
    }

    return (
        <>
            <h3 style={estiloTextoCentrado}>{ titulo }</h3>
            <InputSearch 
                nombre="buscador_receptor"
                placeholder="Busca un Receptor..."
                opciones={opciones}
                changeFunction={buscarReceptor}
                OpcionSelect={(val) => {
                    onChangeReceptor(val)
                }}
            />
            <Input 
                placeholder="RFC Empresa"
                nombre="rfc_empresa"
                iconLeft="fa-id-card"
                value={receptor?.idfiscal}
                disabled={true}
            />
            <Input 
                placeholder="Nombre o Razón Social"
                nombre="nombre_empresa"
                iconLeft="fa-user"
                value={receptor?.razonsocial}
                disabled={true}
            />
            <Select 
                placeholder="Régimen Social"
                nombre="regimen_social"
                iconLeft="fa-user-tie"
                options={regimenSociales}
                value={obtenerValor(regimenSociales, receptor?.regimen, 'Seleccione un regimen social...')}
                disabled={true}
            />
            <Input 
                placeholder="Código Postal"
                nombre="codigo_postal"
                iconLeft="fa-house-user"
                value={receptor?.codigopostal}
                disabled={true}
            />
            <Select 
                nombre="uso_factura"
                iconLeft="fa-hand-holding-dollar"
                options={usosFactura}
                value={obtenerValor(usosFactura, formFactura?.uso_factura, 'Seleccione un uso para la factura...')}
                changeFunction={onChangeEntrada}
                busqueda={{ placeholder: 'Uso de Factura' }}
            />
        </>
    )
}

export default SeccionReceptor