import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_MEMBRESIAS = 'EurekaMeMembresia'
const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'

export const membresiaAPI = createApi({
    reducerPath: 'membresiaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Membresias'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerMembresias: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MEMBRESIAS)
                            .with('eureka_cr_multimedia')
                            .with('eureka_pa_subscripciones')
                            .get()
                }
            },
        }),
        crearMembresia: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MEMBRESIAS)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        guardarLogoMembresia: builder.mutation({
            /**
             * @param {{ id: number, configFile: { file: object, carpeta: string, nombre: string }  }} data
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA)
                let configFile = data.configFile
                if (data.id) body = body.where('id', data.id)
                // else se crea el registro
                body = body.uploadFile(configFile.file, configFile.carpeta, configFile.nombre)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
    }),
})

export const { 
    useObtenerMembresiasQuery,
    useCrearMembresiaMutation,
    useGuardarLogoMembresiaMutation,
} = membresiaAPI