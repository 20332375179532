import { config } from "../config/config"
import { EkQuery } from "../services/query"
import { TABLA_SUBSCRIPTORES } from "../services/subcripcion"
import { crearCliente, crearPlan, crearSubscripcion, editarPlan, recuperarSubscripcion } from "./stripe"
import { token } from 'account-react-eurekasigma'

export const SEMANAL = 'Semanal'
export const MENSUAL = 'Mensual'
export const ANUAL = 'Anual'
export const ILIMITADA = 'De por vida'
export const RECURRENCIAS = [
    { id: SEMANAL, text: SEMANAL, stripe: 'week' },
    { id: MENSUAL, text: MENSUAL, stripe: 'month' },
    { id: ANUAL, text: ANUAL, stripe: 'year' },
    // { id: ILIMITADA, text: ILIMITADA, stripe: null },
]
export const ACTIVA = 'activo'
export const INACTIVA = 'archivado'

const TIEMPO_VERIFICACION = 1000 * 60 * 60

/**
 * 
 * @param {Array} subscripciones 
 * @param {string} estatus
 * @param {boolean} obtenerTodos
 * @returns {Object|Array}
 */
export const obtenerSubscripcion = (subscripciones = [], estatus = 'activo', obtenerTodos = false) => {
    if(subscripciones.length > 0) {
        subscripciones = subscripciones.filter((subscripcion) => {
            if(estatus === 'todos') {
                return subscripcion.estatus !== estatus
            }
            else return subscripcion.estatus === estatus
        })

        if(obtenerTodos) return subscripciones
        else {
            return subscripciones.length > 0 ? subscripciones[0] : null
        }
    }
    else return obtenerTodos ? [] : null
}

export const actualizarSubscripcion = async (subscripcion) => {

}

/**
 * 
 * @param {string} estatus 
 * @param {object} subscripcion 
 * @param {string} token_stripe_producto
 * @returns 
 */
export const cambiarEstatusSubscripcion = async (estatus = 'activa', subscripcion = null, token_stripe_producto = '') => {
    if(!subscripcion) return null
    else {
        let data = null
        if(estatus === ACTIVA) {
            let res = null
            if(subscripcion.token_stripe) {
                data = {
                    planID: subscripcion.token_stripe,
                    estatus: true
                }
                res = await editarPlan(data)
            }
            else {
                let recurrencia = RECURRENCIAS.filter((r) => r.id === subscripcion.recurrencia)
                recurrencia = recurrencia.length > 0 ? recurrencia[0].stripe : null
                data = {
                    planes: JSON.stringify([
                        { 
                            monto: parseFloat(subscripcion.precio), 
                            moneda: subscripcion.moneda, 
                            apodo: subscripcion.nombre, 
                            tipoRecurrenciaPago: recurrencia
                        }
                    ])
                }
                res = await crearPlan(data, token_stripe_producto)
            }
            return res
        }
        else if(estatus === INACTIVA) {
            data = {
                planID: subscripcion.token_stripe,
                estatus: false
            }
            return await editarPlan(data)
        }
        else return null
    }
}

/**
 * 
 * @param {Array} subscripciones 
 * @param {string} tipoRecurrencia 
 * @param {boolean} mostrarTodo 
 * @returns 
 */
export const ordenarSubscripcionesActivas = (subscripciones = [], tipoRecurrencia = MENSUAL, mostrarTodo = false) => {
    if(subscripciones.length === 0) return null
    else if(mostrarTodo) {
        return subscripciones
    }
    else {
        subscripciones = subscripciones.filter((sub) => sub?.eureka_pa_subscripciones[0]?.recurrencia === tipoRecurrencia)
        if(subscripciones.length > 0) return subscripciones[0].eureka_pa_subscripciones[0]
        else return null
    }
}

export const crearClienteStripe = async (usuario = null) => {
    if(usuario) {
        let data = {
            nombre: usuario?.nombre,
            email: usuario?.correo,
        }
        return await crearCliente(data)
    }
    else return {
        ok: false,
        mensaje: 'No hay información para comprobar el pago'
    }
}

export const subscribir = async (clienteID = null, metodoPagoID = null, subscripcionID = null) => {
    if(clienteID && metodoPagoID && subscripcionID) {
        let data = {
            clienteID: clienteID,
            suscripcionID: subscripcionID,
            metodoPagoID: metodoPagoID,
        }
        return await crearSubscripcion(data)
    }
    else {
        return {
            ok: false
        }
    }
}

export const recuperarSubscripcionStripe = async (subscripcionID = null) => {
    if(subscripcionID) {
        let data = { suscripcionID: subscripcionID }
        return await recuperarSubscripcion(data)
    }
    else {
        return {
            ok: false
        }
    }
}

/**
 * 
 * @param {Date} fechaA 
 * @param {Date} fechaB 
 * @returns 
 */
const esMismaFecha = (fechaA = null, fechaB = null) => {
    if(fechaA && fechaB) {
        return fechaA.getFullYear() === fechaB.getFullYear() && fechaA.getMonth() === fechaB.getMonth() && fechaA.getDate() === fechaB.getDate()
    }
    else {
        return false
    }
}

/**
 * 
 * @param {int} id
 * @param {object} body 
 */
const actualizarSubscripcionBD = async (id = null, body = {}) => {
    body = new EkQuery(TABLA_SUBSCRIPTORES).where('id', id).update(body)
    await fetch(`${config.urlCRUFDEK}/CUFD?key=${config.key}&type=API`, {
        method: 'POST',
        headers: {
            'Authorization' : `Bearer ${token()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(body)
    })
}

/**
 * 
 * @param {int} usuarioID 
 */
const verificarEstadoSubscripcion = async (usuarioID = null) => {
    console.log('Verificar Subscripción...')
    let body = new EkQuery(TABLA_SUBSCRIPTORES).where('usuario', usuarioID).get()
    let res = await fetch(`${config.urlCRUFDEK}/READ?key=${config.key}&type=API`, {
        method: 'POST',
        headers: {
            'Authorization' : `Bearer ${token()}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(body)
    })

    if(res.ok) {
        let seTerminoSubscripcion = false
        let subscripciones = await res.json()
        subscripciones = obtenerSubscripcion(subscripciones, 'activo', true)
        subscripciones = subscripciones.filter((sub) => sub.token_stripe !== null) // Quitar subcripcion dada por el admin

        for(let i = 0; i < subscripciones.length; i++) {
            res = await recuperarSubscripcionStripe(subscripciones[i].token_stripe)
            if(res.suscripcion.status === 'active') {
                console.log('activa...')
                let fechaCorteBD = new Date(subscripciones[i].fecha_proximo_corte)
                let fechaProxCorteStripe = new Date(res.suscripcion.current_period_end * 1000)
                let fechaUltCorteStripe = new Date(res.suscripcion.current_period_start * 1000)

                if(!esMismaFecha(fechaCorteBD, fechaProxCorteStripe)) {
                    actualizarSubscripcionBD(subscripciones[i].id, {
                        fecha_ultimo_corte: `${fechaUltCorteStripe.getFullYear()}-${fechaUltCorteStripe.getMonth()+1}-${fechaUltCorteStripe.getDate()}`,
                        fecha_proximo_corte: `${fechaProxCorteStripe.getFullYear()}-${fechaProxCorteStripe.getMonth()+1}-${fechaProxCorteStripe.getDate()}`,
                    })
                }
                // else no actualizar las fechas en la base de datos
            }
            else if(res.suscripcion.status === 'canceled') {
                console.log('cancelada...')
                let tiempoAct = (new Date()).getTime()
                let tiempoCorte = (new Date(res.suscripcion.current_period_end * 1000)).getTime()

                if(tiempoAct >= tiempoCorte) {
                    seTerminoSubscripcion = true
                    await actualizarSubscripcionBD(subscripciones[i].id, { estatus: 'inactivo' })
                }
                // else permitir que la subscripcion llegue al periodo de corte
            }
            else {
                console.log('pendiente...')
                seTerminoSubscripcion = true
                await actualizarSubscripcionBD(subscripciones[i].id, { estatus: 'inactivo' })
            }

            if(seTerminoSubscripcion) window.location.reload()
        }
    }
    else window.location.reload()
}

/**
 * 
 * @param {int} usuarioID 
 */
export const iniciarVerificacionSubscripcion = (usuarioID = null) => {
    verificarEstadoSubscripcion(usuarioID)

    setInterval(() => {
        verificarEstadoSubscripcion(usuarioID)
    }, TIEMPO_VERIFICACION)
}