
/** ROLES DENTRO DEL SISTEMA */
export const ADMINISTRADOR = 'administrador'
export const DISTRIBUIDOR = 'distribuidor'
export const EMISOR = 'emisor'

/** PERMISOS DISPONIBLES */
export const CREAR_USUARIOS = 'c_usuarios'
export const VER_USUARIOS = 'r_usuarios'
export const EDITAR_USUARIOS = 'u_usuarios'
export const ARCHIVAR_USUARIOS = 'd_usuarios'
export const CREAR_MEMBRESIAS = 'c_membresias'
export const VER_MEMBRESIAS = 'r_membresias'
export const EDITAR_MEMBRESIAS = 'u_membresias'
export const ARCHIVAR_MEMBRESIAS = 'd_membresias'
export const DAR_ROL = 'dar_rol'
export const DAR_MEMBRESIA = 'dar_membresia'
export const CREAR_PERFIL_CONNECT = 'c_perfil_connect'
export const CREAR_FACTURAS = 'c_facturas'
export const VER_FACTURAS = 'r_facturas'
export const EDITAR_FACTURAS = 'u_facturas'
export const ARCHIVAR_FACTURAS = 'd_facturas'
export const CREAR_CONCEPTOS = 'c_conceptos'
export const VER_CONCEPTOS = 'r_conceptos'
export const EDITAR_CONCEPTOS = 'u_conceptos'
export const ARCHIVAR_CONCEPTOS = 'd_conceptos'
export const CREAR_RECEPTORES = 'c_receptores'
export const VER_RECEPTORES = 'r_receptores'
export const EDITAR_RECEPTORES = 'u_receptores'
export const ARCHIVAR_RECEPTORES = 'd_receptores'
export const CREAR_LINK = 'c_link'
export const VER_ESTADO_SUBSCRIPCION = 'r_estado_subscripcion'

/**
 * 
 * @param {Array} roles 
 * @returns 
 */
export const soyAdministrador = (roles = []) => {
    let encontrados = roles.filter((rol) => rol.get_rol.nombre === ADMINISTRADOR)
    return encontrados.length > 0
}

/**
 * 
 * @param {Array} roles 
 * @returns 
 */
export const soyDistribuidor = (roles = []) => {
    let encontrados = roles.filter((rol) => rol.get_rol.nombre === DISTRIBUIDOR)
    return encontrados.length > 0
}

/**
 * 
 * @param {Array} roles 
 * @returns 
 */
export const obtenerRol = (roles = []) => {
    let rolAdministrador = null
    let rolDistribuidor = null
    let rolEmisor = null

    roles.map((rol) => {
        if(rol.get_rol.nombre === ADMINISTRADOR) {
            rolAdministrador = ADMINISTRADOR
        }
        else if(rol.get_rol.nombre === DISTRIBUIDOR) {
            rolDistribuidor = DISTRIBUIDOR
        }
        else {
            rolEmisor = EMISOR
        }
        return true
    })

    return rolAdministrador ? rolAdministrador : rolDistribuidor ? rolDistribuidor : rolEmisor
}