import { ModalForm, Select, Input } from 'eureka-design'
import { useObtenerEmisorQuery } from '../../services/emisor'
import { useCrearFacturaMutation, useEditarArchivoFacturaMutation } from '../../services/factura'
import { abrirArchivo, cancelarFactura } from '../../js/factura'
import { useState } from 'react'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

const ModalCancelar = ({ 
    ShowAlert, 
    UUID, 
    facturaID, 
    mostrarModal, 
    cambiarEstadoMostrarModal, 
    motivosCancelacion,
    actualizarFacturar,
    pdfFacturaID,
}) => {
    const [motivoCancelacion, fijaMotivoCancelacion] = useState('')
    const [folioSustitucion, fijaFolioSustitucion] = useState('')
    const [displayInputFolio, fijaDisplayInputFolio] = useState('none')
    const [cancelandoFactura, cambiarEstadoCancelandoFactura] = useState(false)

    const { data: emisor, isLoading: cargandoEmisor } = useObtenerEmisorQuery({ id: infoUsuario().eureka_pa_cuenta_fiscals[0]?.id })
    const [crearFacturaMutation] = useCrearFacturaMutation()
    const [editarArchivoMutation] = useEditarArchivoFacturaMutation()

    const procesarCancelarfactura = async () => {
        if(emisor && UUID.length > 0 && motivoCancelacion.length > 0) {
            cambiarEstadoCancelandoFactura(true)
            let res = await cancelarFactura(emisor, UUID, motivoCancelacion, folioSustitucion, infoUsuario().eureka_cr_multimedia)

            if(res.estatus === 200 || res.estatus === 201) {
                fijaMotivoCancelacion('')
                fijaFolioSustitucion('')
                fijaDisplayInputFolio('none')

                cambiarEstadoMostrarModal(false)
                editarArchivoMutation({
                    id: pdfFacturaID,
                    body: {
                        'nombre': 'acuse_cancelacion.pdf',
                        'archivo': res.data.urlAcuse
                    }
                })
                .unwrap()
                .then(() => {
                    abrirArchivo(res.data.urlAcuse, 'abrir', 'acuse_cancelacion.pdf')

                    crearFacturaMutation({
                        id: facturaID,
                        body: { 'estatus': res.data.estatus === 201 ? 'cancelado' : 'emitida' }
                    })
                    .unwrap()
                    .then(() => {
                        actualizarFacturar()

                        ShowAlert({
                            icono: 'success',
                            titulo: 'Factura Cancelada',
                            mensaje: res.data.mensaje,
                        })
                    })
                })
            }
            else {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error al Cancelar',
                    mensaje: res.data.mensaje,
                    time: 3,
                })
            }
            cambiarEstadoCancelandoFactura(false)
        }
        else {
            ShowAlert({
                icono: 'error',
                titulo: 'Error al Cancelar',
                mensaje: 'Seleccione un motivo de cancelación',
                time: 3,
            })
        }
    }

    if(cargandoEmisor) return
    else {
        return (
            <ModalForm
                titulo={`Cancelar Factura (${UUID})`}
                show={mostrarModal}
                onClose={() => { 
                    if(!cancelandoFactura) {
                        fijaMotivoCancelacion('')
                        fijaFolioSustitucion('')
                        fijaDisplayInputFolio('none')
                        cambiarEstadoMostrarModal(false)
                    }
                    else {
                        ShowAlert({
                            icono: 'error',
                            titulo: 'No Permitido',
                            mensaje: 'Espere a que se cancele la factura para cerrar la ventana',
                            time: 3,
                        })
                    } 
                }}
                botones={[
                    {
                        texto: "Cancelar Factura",
                        onClick: procesarCancelarfactura,
                        iconLeft: "fa-ban",
                    }
                ]}
            >
                <Select 
                    placeholder="Motivo de Cancelacion"
                    iconLeft="fa-ban"
                    options={motivosCancelacion}
                    value={motivoCancelacion}
                    changeFunction={(item) => {
                        if(item.id === '01') {
                            fijaDisplayInputFolio('initial')
                        }
                        else {
                            fijaDisplayInputFolio('none')
                        }
                        fijaMotivoCancelacion(item.id) 
                    }}
                />
                <div style={{ display: displayInputFolio }}>
                    <Input 
                        placeholder="Folio de Sustitución" 
                        iconLeft="fa-hashtag"
                        value={folioSustitucion}
                        changeFunction={(val) => { fijaFolioSustitucion(val) }}
                    />
                </div>
            </ModalForm>
        )
    }
}

export default ModalCancelar