import { config } from "../config/config"
import { crearObjetoURL } from "./factura"

/** Recursos API Eureka Pagos */
const CREAR_DISTRIBUIDOR = 'crear/proveedor'
const RECUPERAR_DISTRIBUIDOR = 'recuperar/proveedor'
const CREAR_PLAN = 'crear/plan'
const EDITAR_PLAN = 'editar/plan'
const CREAR_CLIENTE = 'crear/cliente'
const CREAR_METODO_PAGO = 'crear/metodo_pago'
const RECUPERAR_METODO_PAGO = 'obtener/metodo_pago'
const SUBSCRIBIR = 'suscribirse'
// const COBRO_AUTOMATICO = 'cobroautomatico/suscripcion'
const CANCELAR_SUBSCRIPCION = 'cancelar/suscripcion'
const RECUPERAR_SUBSCRIPCION = 'recuperar/suscripcion'
const CREAR_PRODUCTO = 'crear/producto'
const RECUPERAR_PLAN = 'recuperar/plan'

/**
 * 
 * @param {Object} data 
 * @param {Object} usuario
 * @returns 
 */
export const crearDistribuidorStripe = async (data = {}, usuario = {}, tipo = 'crear') => {
    let formData = new FormData()

    let separaciones = data.nacimiento.split('-')
    let archivos = usuario?.eureka_cr_multimedia
    let identificacionFrontal = null, identificacionTrasera
    for(let i = 0; i < archivos.length; i++) {
        if(archivos[i].carpeta === 'identificacion_frontal') identificacionFrontal = await crearObjetoURL(archivos[i])
        else if(archivos[i].carpeta === 'identificacion_trasera') identificacionTrasera = await crearObjetoURL(archivos[i])
    }

    formData.append('fotoIdentificacionFrontal', identificacionFrontal)
    formData.append('fotoIdentificacionTrasera', identificacionTrasera)
    formData.append('nombreRepresentante', data.nombre)
    formData.append('apellidoRepresentante', data.apellidos)
    formData.append('telefonoRepresentante', data.telefono)
    formData.append('anioNacimientoRepresentante', parseInt(separaciones[0]))
    formData.append('mesNacimientoRepresentante', parseInt(separaciones[1]))
    formData.append('diaNacimientoRepresentante', parseInt(separaciones[2]))
    formData.append('email', usuario?.correo)
    formData.append('direccion1Representante', data.direccion1_empresa)
    formData.append('direccion2Representante', 'Ningúna')
    formData.append('codigoPostalRepresentante', data.cp_empresa)
    formData.append('pais', data.pais_empresa)
    formData.append('estadoRepresentante', data.estado_empresa)
    formData.append('ciudadRepresentante', data.ciudad_empresa)
    formData.append('monedaCuentaBancaria', data.moneda)
    formData.append('paisCuentaBancaria', data.pais_empresa)
    formData.append('numeroCuentaBancaria', data.numero_cuenta)
    formData.append('seRegistraEmpresa', 0)

    let res = await fetch(
        `${config.urlEurekaPagos}/${CREAR_DISTRIBUIDOR}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            body: formData
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {string} id 
 */
export const recuperarDistribuidorStripe = async (id = '') => {
    if(id?.length > 0) {
        let res = await fetch(
            `${config.urlEurekaPagos}/${RECUPERAR_DISTRIBUIDOR}?key=${config.keyEurekaPagos}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token_stripe: id })
            }
        )
        let ok = res.ok
        res = await res.json()
        return {
            ...res,
            ok
        }
    }
    else {
        return {
            ok: false
        }
    }
}

/**
 * 
 * @param {object} data 
 * @param {string} productoID
 * @returns 
 */
export const crearPlan = async (data = {}, productoID = '') => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${CREAR_PLAN}/${productoID}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const editarPlan = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${EDITAR_PLAN}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const crearCliente = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${CREAR_CLIENTE}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const crearMetodoPago = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${CREAR_METODO_PAGO}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const crearSubscripcion = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${SUBSCRIBIR}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const recuperarSubscripcion = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${RECUPERAR_SUBSCRIPCION}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const recuperarMetodoPago = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${RECUPERAR_METODO_PAGO}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}


/**
 * 
 * @param {object} data 
 * @returns 
 */
export const cancelarSubscripcion = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${CANCELAR_SUBSCRIPCION}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const crearProducto = async (data = {}) => {
    let res = await fetch(
        `${config.urlEurekaPagos}/${CREAR_PRODUCTO}?key=${config.keyEurekaPagos}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }
    )
    let ok = res.ok
    res = await res.json()
    return {
        ...res,
        ok
    }
}

/** 
* @param {string} id 
* @returns 
*/
export const recuperarPlan = async (id = '') => {
    if(id?.length > 0) {
        let res = await fetch(
            `${config.urlEurekaPagos}/${RECUPERAR_PLAN}/${id}?key=${config.keyEurekaPagos}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        )
        let ok = res.ok
        res = await res.json()
        return {
            ...res,
            ok
        }
    }
    else {
        return {
            plan: null,
            ok: false
        } 
    }
}