import { ModalForm, Input, Select, Chip } from 'eureka-design'
import VentanaSinSubscripcion from "../../components/subscripciones/VentanaSinSubscripcion"
import { useCrearSubscriptorMutation, useCrearTarjetaMutation, useObtenerMembresiasActivasQuery, useObtenerTarjetasQuery } from "../../services/subcripcion"
import { useEffect, useState } from 'react'
import { crearClienteStripe, obtenerSubscripcion, subscribir } from '../../js/subscripcion'
import '../../css/util.css'
import { MESES, crearMetodoPagoStripe, obtenerAniosVencimiento } from '../../js/tarjeta'
import { obtenerValor } from '../../js/factura'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { useCrearContactoComisionMutation, useCrearEmisorMutation, useObtenerContactosExistentesQuery, useObtenerEmisorQuery } from '../../services/emisor'
import { capitalize } from '../../js/util'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { config } from '../../config/config'
import Cargando from '../../components/util/Cargando'

const FORM_TARJETA = 'form_tarjeta'

const Subscribirse = ({ UpProps, ShowAlert, form_tarjeta, subscripciones }) => {
    const { data: membresias, isLoading: cargandoMembresias } = useObtenerMembresiasActivasQuery()
    const { data: emisor, isLoading: cargandoDatosFiscales } = useObtenerEmisorQuery({ id: infoUsuario().eureka_pa_cuenta_fiscals[0].id })
    const { data: tarjetas, isLoading: cargandoTarjetas } = useObtenerTarjetasQuery({ usuarioID: infoUsuario().id })
    const { data: contactos, isLoading: cargandoContactos } = useObtenerContactosExistentesQuery({ usuarioID: infoUsuario().id })

    const [crearEmisorMutation] = useCrearEmisorMutation()
    const [crearTarjetaMutation] = useCrearTarjetaMutation()
    const [crearSubscriptorMutation] = useCrearSubscriptorMutation()
    const [crearContacto] = useCrearContactoComisionMutation()
    const [subcriptorExistenteID, fijaSubscriptorExistenteID] = useState(null)

    const [subscripcion, fijaSubscripcion] = useState(null)
    const [abrirModal, cambiarEstadoAbrirModal] = useState(false)
    const [subscribiendo, cambiarEstadoSubscribiendo] = useState(false)
    const [displayAgregarTarjeta, fijaDisplayAgregarTarjeta] = useState('none')
    const [tarjetaSeleccionada, fijaTarjetaSeleccionada] = useState(null)

    useEffect(() => {
        fijaDisplayAgregarTarjeta(tarjetas?.length > 0 ? 'none' : 'contents')
        fijaSubscriptorExistenteID(obtenerSubscripcion(subscripciones, 'inactivo', false)?.id)
    }, [membresias, fijaSubscripcion, tarjetas, fijaDisplayAgregarTarjeta, subscripciones])

    const handlerContactoDistribuidor = async () => {
        let distribuidorID = localStorage.getItem('invitacion')
        if(distribuidorID.length > 0 && contactos.length === 0) {
            console.log('relacionar con distribuidor...')
            distribuidorID = AES.decrypt(distribuidorID, config.keySecretaEncriptar).toString(Utf8)
            await crearContacto({
                body: {
                    relacion: 'comision',
                    estatus: 'activo',
                    usuario: parseInt(distribuidorID),
                    contacto: infoUsuario().id
                }
            })
            .unwrap()
            .then(() => {
                localStorage.removeItem('invitacion')
            })
            
            // Realizar comisión en Stripe
        }
        else return
    }

    const handlerSubscripcion = async (subscripcionStripe) => {
        let fechaCreacion = new Date(subscripcionStripe.created * 1000)
        let fechaUltCorte = new Date(subscripcionStripe.current_period_start * 1000)
        let fechaProxCorte = new Date(subscripcionStripe.current_period_end * 1000)

        await crearSubscriptorMutation({
            id: subcriptorExistenteID,
            body: {
                fecha_subscripcion: `${fechaCreacion.getFullYear()}-${fechaCreacion.getMonth()+1}-${fechaCreacion.getDate()}`,
                fecha_ultimo_corte: `${fechaUltCorte.getFullYear()}-${fechaUltCorte.getMonth()+1}-${fechaUltCorte.getDate()}`,
                fecha_proximo_corte: `${fechaProxCorte.getFullYear()}-${fechaProxCorte.getMonth()+1}-${fechaProxCorte.getDate()}`,
                token_stripe: subscripcionStripe.id,
                estatus: subscripcionStripe.status === 'active' ? 'activo' : 'inactivo',
                usuario: infoUsuario().id,
                subscripcion: subscripcion.id,
            }
        })
        .unwrap()
        .then(() => {
            handlerContactoDistribuidor()
            ShowAlert({
                icono: 'success',
                titulo: 'Pago con Exito',
                mensaje: 'Su subscripción a iniciado, por favor espere.',
            })
            window.location.reload()
        })
    }

    const handlerClienteStripe = async () => {
        let clienteID
        if(emisor?.token_stripe) clienteID = emisor.token_stripe
        else {
            let res = await crearClienteStripe(infoUsuario())
            if(res.ok) {
                clienteID = res.cliente.id
                crearEmisorMutation({
                    id: emisor.id,
                    body: { 'token_stripe': clienteID }
                })
            }
            else {
                clienteID = null
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error al comprobar información',
                    mensaje: 'Verifique que su correo y nombre sean correctos para continuar.',
                    time: 5,
                })
            }
        }
        return clienteID
    }

    const handlerMetodoPago = async (clienteID) => {
        let tarjetaID

        if(tarjetaSeleccionada) tarjetaID = tarjetaSeleccionada.token_stripe
        else {
            let res = await crearMetodoPagoStripe(clienteID, form_tarjeta, infoUsuario())
            if(res.ok) {
                tarjetaID = res.metodoPago.id
                if(form_tarjeta.guardar) {
                    crearTarjetaMutation({
                        id: null,
                        body: {
                            tipo: String(res.metodoPago.card.brand).toUpperCase(),
                            numero: res.metodoPago.card.last4,	
                            vencimiento: `${res.metodoPago.card.exp_month}/${res.metodoPago.card.exp_year}`,
                            token_stripe: res.metodoPago.id,
                            cliente_stripe: res.metodoPago.customer,
                            usuario: infoUsuario().id
                        }
                    })
                }
                else {
                    // eliminar la tarjeta de stripe
                }
            }
            else {
                tarjetaID = null
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error al comprobar tarjeta',
                    mensaje: 'Los datos de tu tarjeta son erroneos por favor verifiquelos.',
                    time: 5,
                })
            }
        }
        return tarjetaID
    }

    const subscribirse = async () => {
        if(subscribiendo) {
            ShowAlert({
                icono: 'warning',
                titulo: 'Espere un Momento',
                mensaje: 'Estamos comprobando su pago, por favor espere',
                time: 5,
            })
        }
        else {
            cambiarEstadoSubscribiendo(true)
            let clienteID = await handlerClienteStripe()
            if(clienteID) {
                let tarjetaID = await handlerMetodoPago(clienteID)
                if(tarjetaID) {
                    let res = await subscribir(clienteID, tarjetaID, subscripcion.token_stripe)
                    if(res.ok) handlerSubscripcion(res.suscripcion)
                    else {
                        ShowAlert({
                            icono: 'error',
                            titulo: 'Error al subscribirte',
                            mensaje: 'Sucedio un error, intentelo nuevamente.',
                            time: 5,
                        })
                    }
                    fijaTarjetaSeleccionada(null)
                    cambiarEstadoAbrirModal(false)
                }
                else return
            }
            else return
            cambiarEstadoSubscribiendo(false)
        }
    }

    const handlerAbrirModal = (subElegida) => {
        fijaSubscripcion(subElegida)
        cambiarEstadoAbrirModal(true)
    }

    const handlerCerrarModal = () => {
        if(subscribiendo) {
            ShowAlert({
                icono: 'warning',
                titulo: 'Espere un Momento',
                mensaje: 'Estamos comprobando su pago, por favor espere',
                time: 5,
            })
        }
        else {
            cambiarEstadoAbrirModal(false)
        }
    }

    const handlerAgregarTarjeta = (e) => {
        if(e.target.checked) fijaDisplayAgregarTarjeta('contents')
        else fijaDisplayAgregarTarjeta('none') 
    }

    const handlerSeleccionarTarjeta = (cambiarEstadoChip, tarjeta) => {
        cambiarEstadoChip(true)
        fijaTarjetaSeleccionada(tarjeta)
    }

    const handlerState = (val, nom) => {
        val = (val?.target) ? val.target.checked : (val?.id) ? val.id : val
        nom = (nom) ? nom : 'guardar'

        UpProps({
            [FORM_TARJETA]: {
                ...form_tarjeta,
                [nom]: val
            }
        })
    }

    if(cargandoMembresias || cargandoDatosFiscales || cargandoTarjetas || cargandoContactos) return
    else {
        let chipsTarjetas
        if(tarjetas.length > 0) {
            chipsTarjetas = tarjetas.map((tarjeta, index) => {
                let chip = <Chip 
                    icono="fa-credit-card"
                    texto={`${capitalize(tarjeta.tipo)} que termina en ${tarjeta.numero} - vence el ${tarjeta.vencimiento}`}
                    onClick={(cambiarEstadoChip) => { handlerSeleccionarTarjeta(cambiarEstadoChip, tarjeta) }}
                />

                if(index === 0) {
                    return (
                        <div key={index}>
                            <h3>Tus Tarjetas</h3>
                            <br />
                            {chip}
                            {
                                (tarjetas.length === 1) ?
                                    <>
                                        <br />
                                        <label className="checkbox">
                                            <input type="checkbox" style={{ display: 'none' }} onChange={handlerAgregarTarjeta} />
                                            <i className="fa fa-plus"></i> 
                                            Agregar Tarjeta de Credito o Debito
                                        </label>
                                    </> : null
                            }
                        </div>
                    )
                }
                else if(tarjetas.length-1 === index) {
                    return (
                        <div key={index}>
                            {chip}
                            <br />
                            <label className="checkbox">
                                <input type="checkbox" style={{ display: 'none' }} onChange={handlerAgregarTarjeta} />
                                <i className="fa fa-plus"></i> 
                                Agregar Tarjeta de Credito o Debito
                            </label>
                        </div>
                    )
                }
                else return chip
            })
        }
        else chipsTarjetas = null

        return (
            <>
                <ModalForm
                    titulo={`Subscribirse a ${subscripcion?.nombre}`}
                    show={abrirModal}
                    botones={[
                        {
                            iconLeft: "fa-hand-holding-dollar",
                            texto: 'Pagar Subscripción',
                            onClick: subscribirse,
                        }
                    ]}
                    onClose={handlerCerrarModal}
                >
                    {
                        subscribiendo ?
                            <Cargando mensaje="Comprobando pago, por favor espere un momento..." /> :
                            <>
                                {chipsTarjetas}

                                <div className="div-oculto" style={{ display: displayAgregarTarjeta }}>
                                    <h3>Agregar nueva tarjeta</h3>
                                    <Input 
                                        iconLeft="fa-user"
                                        nombre="nombre"
                                        placeholder="Titular de la Tarjeta"
                                        changeFunction={handlerState}
                                        value={form_tarjeta?.nombre}
                                    />
                                    <Input 
                                        iconLeft="fa-credit-card"
                                        nombre="numero"
                                        placeholder="Número de la Tarjeta"
                                        changeFunction={handlerState}
                                        value={form_tarjeta?.numero}
                                    />
                                    <Select 
                                        iconLeft="fa-calendar-days"
                                        nombre="mes"
                                        placeholder="Mes de Vencimiento"
                                        options={MESES}
                                        changeFunction={handlerState}
                                        value={obtenerValor(MESES, form_tarjeta?.mes, 'Seleccione un mes...')}
                                    />
                                    <Select 
                                        iconLeft="fa-calendar"
                                        nombre="anio"
                                        placeholder="Año de Vencimiento"
                                        options={obtenerAniosVencimiento()}
                                        value={obtenerValor(obtenerAniosVencimiento(), form_tarjeta?.anio, 'Seleccione un año...')}
                                        changeFunction={handlerState}
                                    />
                                    <Input 
                                        iconLeft="fa-key"
                                        nombre="cvv"
                                        placeholder="CVV"
                                        changeFunction={handlerState}
                                        value={form_tarjeta?.cvv}
                                    />
                                    <label className="checkbox">
                                        {
                                            (form_tarjeta?.guardar) ?
                                                <input type="checkbox" onChange={handlerState} checked/> :
                                                <input type="checkbox" onChange={handlerState}/>
                                        }
                                        Guardar tarjeta para proximos pagos
                                    </label>
                                </div>
                            </>
                    
                    }
                </ModalForm>

                <VentanaSinSubscripcion 
                    membresias={membresias}
                    handlerSubscribirse={handlerAbrirModal}
                />
            </>
        )
    }
}

export default Subscribirse
