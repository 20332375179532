 import { Formulario, Input, Select, ImageInput, DropFile } from 'eureka-design'
 import { crearCookie } from 'account-react-eurekasigma'
 import nologo from '../../img/no-logo.png'
import { 
    useCrearEmisorMutation, 
    useEditarArchivoMutation, 
    useGuardarCertificadoMutation, 
    useGuardarIdentificacionMutation, 
    useGuardarLogoMutation,
    useObtenerArchivoMutation,
} from '../../services/emisor'
import { useState } from 'react'
import { obtenerValor } from '../../js/factura'
import { obtenerLogo, obtenerMiArchivo } from '../../js/util'
import { config } from '../../config/config'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { handler_rol } from '../../js/handler_rol'
import { CREAR_PERFIL_CONNECT } from '../../js/roles'
import SeccionFormConnect from '../../components/stripe/SeccionFormConnect'
import { crearDistribuidorStripe } from '../../js/stripe'

export const FORM_PERFIL = 'form_perfil'

const EditarPerfil = ({ 
    ShowAlert, 
    UpProps, 
    regimenesSociales, 
    form_perfil,
    paises, 
    monedas, 
    roles 
}) => {
    const usuario = infoUsuario()
    const [getPermiso] = handler_rol(roles)

    const [crearEmisor, {
        isSuccess: emisorActualizado,
        isLoading: actualizandoEmisor,
        isError: errorEmisor,
    }] = useCrearEmisorMutation()
    const [guardarLogo, {
        isSuccess: logoGuardado,
        isLoading: actualizandoLogo,
        isError: errorActualizarLogo,
    }] = useGuardarLogoMutation()
    const [guardarCertificado, {
        isSuccess: certificadoGuardado,
        isLoading: actualizandoCertificado,
        isError: errorActualizarCertificado,
    }] = useGuardarCertificadoMutation()
    const [guardarIdentificacionMutation, {
        isSuccess: identificacionGuardado,
        isLoading: actualizandoIdentificacion,
        isError: errorActualizarIdentificacion,
    }] = useGuardarIdentificacionMutation()
    const [editarArchivo] = useEditarArchivoMutation()
    const [obtenerInfoArchivo] = useObtenerArchivoMutation()

    /** Estados Locales */
    const [emisorID] = useState(usuario.eureka_pa_cuenta_fiscals[0]?.id)
    const [logo, fijaLogo] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'logo'))
    const [certificado, fijaCertificado] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'certificado'))
    const [clavePrivada, fijaClavePrivada] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'clave_privada'))
    const [identificacionFrontal, fijaIdentificacionFrontal] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'identificacion_frontal'))
    const [identificacionTrasera, fijaIdentificacionTrasera] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'identificacion_trasera'))
    const [tokenStripe, fijaTokenStripe] = useState(form_perfil?.token_stripe)
    const [mostrarModal, cambiarEstadoMostrarModal] = useState(false)
    const [guardandoPerfilStripe, fijaGuardandoPerfilStripe] = useState(false)

    const editarTipoArchivo = (archivo = {}, tipo = 'imagen') => {
        editarArchivo({
            id: archivo?.id,
            body: {
                tipo: tipo,
                usuario: usuario.id
            }
        })
    }

    const editarEstadoArchivosLocal = async (archivo = {}, carpeta = '', nomNuevo = '') => {
        let archivos = []
        if(nomNuevo === '') {
            nomNuevo = await obtenerInfoArchivo({
                id: archivo?.id,
            })
            .unwrap()
            .then((res) => {
                if(res) return res?.nombre
                else return ''
            })

            archivos = infoUsuario().eureka_cr_multimedia?.map((archivo) => {
                if(archivo.carpeta === carpeta) {
                    return {
                        ...archivo,
                        nombre: nomNuevo
                    }
                }
                else return archivo
            })
        }
        else {
            archivos.push(archivo)
            archivos = [
                ...infoUsuario().eureka_cr_multimedia,
                ...archivos
            ]
        }
        
        const usuarioEditado = {
            ...usuario,
            eureka_cr_multimedia: archivos
        }
        // dispatch(fijaUsuario(usuarioEditado))
        crearCookie(config.nomCookieUsuario, JSON.stringify(usuarioEditado), true, config.expCookie)
        return nomNuevo
    }

    const cambiarLogo = async (e, url) => {
        return await guardarLogo({
            id: logo?.id,
            configFile: { file: e.target.files[0], carpeta: 'logo', nombre: 'nombre' }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let logoCreado = res.mensaje
                fijaLogo(logoCreado)
                editarTipoArchivo(logoCreado, 'imagen')
                editarEstadoArchivosLocal(logoCreado, 'logo', logoCreado.nombre)
                return logoCreado.id
            }
            else {
                editarEstadoArchivosLocal(logo, 'logo', '')
                return logo.id
            }
        })
    }

    const cambiarCertificado = async (archivo, nom) => {
        let configArchivo = {}
        if(nom === 'certificado') {
            configArchivo = {
                id: certificado?.id,
                archivo: certificado,
                carpeta: nom,
                col: 'cetificado'
            }
        }
        else if(nom === 'clave_privada') {
            configArchivo = {
                id: clavePrivada?.id,
                archivo: clavePrivada,
                carpeta: nom,
                col: 'llave'
            }
        }

        return await guardarCertificado({
            id: configArchivo?.id,
            configFile: { file: archivo, carpeta: nom, nombre: 'nombre' }
        })
        .unwrap()
        .then((res) => {
            ShowAlert({
                icono: 'success',
                titulo: 'Editado con Éxito',
                mensaje: 'Tu archivo a sido modificado correctamente',
                time: 3,
            })

            if(res?.mensaje?.id) {
                let archivoCreado = res.mensaje
                if(nom === 'certificado') fijaCertificado(archivoCreado)
                else if(nom === 'clave_privada') fijaClavePrivada(archivoCreado)
                cambiarEntrada(1, configArchivo.col)
                editarTipoArchivo(archivoCreado, 'archivo')
                editarEstadoArchivosLocal(archivoCreado, configArchivo.carpeta, archivoCreado.nombre)
                return archivoCreado.id
            }
            else {
                editarEstadoArchivosLocal(configArchivo.archivo, configArchivo.carpeta, '')
                return configArchivo.id
            }
        })
    }

    const guardarIdentificacion = async (archivo, nom) => {
        return await guardarIdentificacionMutation({
            id: nom === 'identificacion_frontal' ? identificacionFrontal?.id : identificacionTrasera?.id,
            configFile: { file: archivo, carpeta: nom, nombre: 'nombre' }
        })
        .unwrap()
        .then(async (res) => {
            if(res?.mensaje?.id) {
                let archivoCreado = res.mensaje
                if(nom === 'identificacion_frontal') {
                    fijaIdentificacionFrontal(archivoCreado)
                }
                else {
                    fijaIdentificacionTrasera(archivoCreado)
                }
                editarTipoArchivo(archivoCreado, 'imagen')
                editarEstadoArchivosLocal(archivoCreado, nom, archivoCreado.nombre)
                return archivoCreado.id
            }
            else {
                let archivo = nom === 'identificacion_frontal' ? identificacionFrontal : identificacionTrasera
                let nomNuevo = await editarEstadoArchivosLocal(archivo, nom, '')
                if(nom === 'identificacion_frontal') {
                    fijaIdentificacionFrontal({
                        ...archivo,
                        nombre: nomNuevo
                    })
                }
                else {
                    fijaIdentificacionTrasera({
                        ...archivo,
                        nombre: nomNuevo
                    })
                }
                return archivo.id
            }
        })
    }

    const editarEmisor = async (nom, val) => {
        return await crearEmisor({
            id: emisorID,
            body: { [nom]: val }
        })
        .unwrap()
        .then((res) => {
            if(res.mensaje?.id) return res.mensaje.id
            else return emisorID
        })
    }

    const cambiarEntrada = (val, nom) => {
        if(val instanceof Date) {
            val = `${val.getFullYear()}-${val.getMonth()+1}-${val.getDate()}`
        }
        // else modificar el valor

        editarEmisor(nom, val)
        handlerFormData(nom, val)
    }

    const cambiarSelector = (obj, nom) => {
        editarEmisor(nom, obj.id)
        handlerFormData(nom, obj.id)
    }

    const guardarPerfilDistribuidor = async (tipo = 'crear') => {
        if(guardandoPerfilStripe) {
            ShowAlert({
                icono: 'warning',
                titulo: 'Guardando Información',
                mensaje: 'Espere a que el proceso termine para evitar errores.',
                time: 3,
            })
        }
        else {
            fijaGuardandoPerfilStripe(true)
            let data = await crearDistribuidorStripe(form_perfil, infoUsuario(), tipo)
            cambiarEstadoMostrarModal(false)
            fijaGuardandoPerfilStripe(false)
            
            if(data.ok) {
                cambiarEntrada(data.proveedor.id, 'token_stripe')
                fijaTokenStripe(data.proveedor.id)
                ShowAlert({
                    icono: 'success',
                    titulo: 'Perfil Creado',
                    mensaje: 'Su perfil de distribuidor a sido creado correctamente',
                    time: 5,
                })
            }
            else {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Información Faltante',
                    mensaje: 'Complete toda su información para poder guardar su perfil de distribuidor',
                })
            }
        }
    }

    const handlerFormData = (key, val) => {
        UpProps({ 
            [FORM_PERFIL]: { 
                ...form_perfil,
                [key]: val 
            } 
        })
    }

    return (
        <Formulario
            titulo="Perfil de Emisor"
            isLoading={[actualizandoEmisor, actualizandoLogo, actualizandoCertificado, actualizandoIdentificacion]}
            isSuccess={[emisorActualizado, logoGuardado, certificadoGuardado, identificacionGuardado]}
            isError={[errorEmisor, errorActualizarLogo, errorActualizarCertificado, errorActualizarIdentificacion]}
            botones={getPermiso(CREAR_PERFIL_CONNECT) ? [
                {
                    texto: "Guardar Información Distribuidor",
                    iconLeft: "fa-user-tie",
                    onClick: () => { cambiarEstadoMostrarModal(true) },
                }
            ] : []}
        >
            <ImageInput 
                width={200}
                height={200}
                avatar={true}
                preview={obtenerLogo(usuario.eureka_cr_multimedia ?? [], nologo, 'logo')}
                changeFunction={cambiarLogo}
            />
            <h3>Información Fiscal</h3>
            <Input 
                placeholder="RFC"
                type="text"
                nombre="idfiscal"
                changeFunction={cambiarEntrada}
                iconLeft="fa-id-card"
                value={form_perfil?.idfiscal ?? ''}
            />
            <Input 
                placeholder="Nombre o Razón Social"
                type="text"
                nombre="razonsocial"
                changeFunction={cambiarEntrada}
                iconLeft="fa-user"
                value={form_perfil?.razonsocial ?? ''}
            />
            <Input 
                placeholder="Contraseña"
                type="password"
                nombre="clave"
                changeFunction={cambiarEntrada}
                iconLeft="fa-key"
                value={form_perfil?.clave ?? ''}
            />
            <Select 
                iconLeft="fa-user-tie"
                options={regimenesSociales}
                value={obtenerValor(regimenesSociales, form_perfil?.regimen, 'Seleccione un régimen social...')}
                nombre="regimen"
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'Régimen Social' }}
            />
            <Input 
                placeholder="Código Postal"
                type="text"
                nombre="codigopostal"
                changeFunction={cambiarEntrada}
                iconLeft="fa-house"
                value={form_perfil?.codigopostal ?? ''}
            />
            
            <h3>Certificado (.cer)</h3>
            <DropFile 
                nombre="certificado"
                allowedExtensions={['.cer']}
                onChange={cambiarCertificado}
            />

            <h3>Clave Privada (.key)</h3>
            <DropFile 
                nombre="clave_privada"
                allowedExtensions={['.key']}
                onChange={cambiarCertificado}
            />

            {
                getPermiso(CREAR_PERFIL_CONNECT) ?
                    <SeccionFormConnect 
                        tokenStripe={tokenStripe}
                        form_perfil_connect={form_perfil}
                        paises={paises}
                        monedas={monedas}
                        handlerCrearPerfil={guardarPerfilDistribuidor}
                        mostrarModal={mostrarModal}
                        cambiarEstadoMostrarModal={cambiarEstadoMostrarModal}
                        cambiarEntrada={cambiarEntrada}
                        cambiarSelector={cambiarSelector}
                        guardarIdentificacion={guardarIdentificacion}
                        guardandoPerfilStripe={guardandoPerfilStripe}
                    /> : null
            }
        </Formulario>
    )
 }

 export default EditarPerfil