import { PerfilCard, Button } from 'eureka-design'
import { capitalize, obtenerLogo } from '../../js/util'
import nologo from '../../img/no-logo.png'

 const InfoMembresia = ({ membresia, handlerSubscripciones = () => {} }) => {
    const planesDisponibles = (planes = []) => {
        let disponibles = planes.filter((plan) => {
            return plan?.token_stripe?.length > 0
        })
        return disponibles.length
    }

    return (
        <div>
            <PerfilCard
                portada={obtenerLogo(membresia.eureka_cr_multimedia, nologo)}
                titulo={capitalize(`Membresía ${membresia.nombre ?? 'Sin Nombre'}`)}
            >
                <div className="seccion-membresias__info">
                    <p className="seccion-membresias__info__texto">
                        {planesDisponibles(membresia.eureka_pa_subscripciones)} plan(es) disponible(s)
                    </p>
                    <Button texto="ELEGIR MEMBRESÍA" size="medium" onClick={() => { handlerSubscripciones(membresia) }} />
                </div>
            </PerfilCard>
        </div>
    )
 }

 export default InfoMembresia