import { ModalForm } from 'eureka-design'
import { useCrearUsuarioMutation } from '../../services/usuario'

const ModalArchivar = ({ 
    alert = () => {}, 
    mostrarModal = false,
    cambiarEstadoModal = () => {},
    usuario = null,
    actualizarUsuarios = () => {}
}) => {
    const [editarUsuario] = useCrearUsuarioMutation()

    const archivar = () => {
        editarUsuario({
            id: usuario.id,
            body: {
                estatus: 'bloqueado'
            }
        })
        .unwrap()
        .then(() => {
            actualizarUsuarios()
            cambiarEstadoModal(false)
            alert({
                icono: 'success',
                titulo: 'Usuario Archivado',
                mensaje: 'El usuario ha sido archivado correctamente',
                time: 3,
            })
        })
    }

    return (
        <ModalForm 
            titulo={`¿Segúro de que quiere archivar al usuario (${usuario?.nombre ?? 'Nombre'})?`}
            show={mostrarModal}
            onClose={() => { cambiarEstadoModal(false) }}
            botones={[
                {
                    texto: "Archivar Usuario",
                    onClick: archivar,
                    iconLeft: "fa-trash",
                }
            ]}
        >
            <p><i className="fa fa-circle-info"></i> Al archivar este usuario no se eliminara completamente de la plataforma, pero quedará como bloqueado dentro de la misma y no podrá usar la plataforma correctamente.</p>
        </ModalForm>
    )
}

export default ModalArchivar