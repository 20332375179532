import { Theme, Navegador, CargandoPagina } from 'eureka-design'
import nouser from '../../img/nouser.jpg'
import PaginaClientes from '../clientes/Clientes'
import PaginaFacturas from '../facturas/Facturas'
import PaginaConceptos from '../conceptos/Conceptos'
import Perfil from '../perfil/Perfil'
import EditarPerfil from '../perfil/EditarPerfil'
import FormCliente from '../clientes/FormCliente'
import FormConcepto from '../conceptos/FormConcepto'
import GenerarFactura from '../facturas/GenerarFactura'
import { capitalize, obtenerLogo } from '../../js/util'
import DetalleCliente from '../clientes/DetalleCliente'
import { config } from '../../config/config'
import { useEffect, useState } from 'react'
import { 
    CATALOGO_CLAVES_UNIDAD,
    CATALOGO_FORMAS_PAGOS,
    CATALOGO_MONEDAS, 
    CATALOGO_MOTIVOS_CANCELACION, 
    CATALOGO_PAISES, 
    CATALOGO_REGIMENES_FISCALES, 
    REGLAS_TASA_CUOTA, 
    adaptarCatalogoSAT, 
    obtenerCatalogoSAT 
} from '../../js/factura'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import Usuarios from '../usuarios/Usuarios'
import EditarUsuario from '../usuarios/EditarUsuario'
import { useObtenerSubscripcionesUsuarioQuery } from '../../services/subcripcion'
import { obtenerSubscripcion } from '../../js/subscripcion'
import { useObtenerRolesUsuariosQuery } from '../../services/usuario'
import Membresias from '../membresias/Membresias'
import { handler_rol } from '../../js/handler_rol'
import { VER_CONCEPTOS, VER_FACTURAS, VER_MEMBRESIAS, VER_RECEPTORES, VER_USUARIOS, obtenerRol, soyAdministrador, soyDistribuidor } from '../../js/roles'
import FormMembresia from '../membresias/FormMembresia'
import Subscribirse from '../subscripciones/Subscribirse'
import { useNavigate } from 'react-router-dom'
import EstadoSubscripcion from '../subscripciones/EstadoSubscripcion'
import Subscripciones from '../subscripciones/Subscripciones'
import FormSubscripcion from '../subscripciones/FormSubscripcion'

export default function Index() {
    const navegacion = useNavigate()
    const usuario = infoUsuario()

    const [cargandoCatalogos, cambiarEstadoCargandoCatalogo] = useState(true)
    const [regimenesSociales, fijaRegimenesSociales] = useState([])
    const [monedas, fijaMonedas] = useState([])
    const [clavesUnidad, fijaClavesUnidad] = useState([])
    const [formasPagos, fijaFormasPagos] = useState([])
    const [reglasTasaCuota, fijaReglasTasaCuota] = useState([])
    const [motivosCancelacion, fijaMotivosCancelacion] = useState([])
    const [paises, fijaPaises] = useState([])

    const { data: subscripciones, isLoading: caragandoSubscripciones } = useObtenerSubscripcionesUsuarioQuery({ usuarioID: usuario.id })
    const { data: roles, isLoading: cargandoRol } = useObtenerRolesUsuariosQuery({ usuarioID: usuario.id })
    const [getPermiso] = handler_rol(roles)

    useEffect(() => {
        const cargarCatalogos = async () => {
            fijaRegimenesSociales(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_REGIMENES_FISCALES), true))
            fijaMonedas(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_MONEDAS), true))
            fijaClavesUnidad(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_CLAVES_UNIDAD), true))
            fijaFormasPagos(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_FORMAS_PAGOS), true))
            fijaReglasTasaCuota(await obtenerCatalogoSAT(REGLAS_TASA_CUOTA))
            fijaMotivosCancelacion(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_MOTIVOS_CANCELACION), true, 'id', 'motivo'))
            fijaPaises(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_PAISES), true, 'codigo', 'nombre'))
            cambiarEstadoCargandoCatalogo(false)
        }
        if(cargandoCatalogos) cargarCatalogos()
        // else catalogos del SAT cargados

        if(usuario?.estatus === 'bloqueado') navegacion('/cerrar_sesion')
    }, [cargandoCatalogos, cambiarEstadoCargandoCatalogo])

    if(!usuario || cargandoCatalogos || caragandoSubscripciones || cargandoRol) 
        return <CargandoPagina mensaje="Cargando, por favor espere..." colorPrincipal={config.colorPrincipal} />
    else {
        let haySubscripcionActiva = obtenerSubscripcion(subscripciones, 'activo', true).length > 0
        let componentes = {}
        let componentePerfil = {
            'Perfil': {
                pantallas: {
                    perfil: {
                        props: { regimenesSociales: regimenesSociales, roles, subscripciones },
                        titulo: 'Mi Perfil',
                        componente: Perfil
                    },
                    editar_perfil: {
                        props: { regimenesSociales: regimenesSociales, paises, monedas, roles },
                        titulo: 'Editar Perfil',
                        componente: EditarPerfil
                    },
                    estado_subscripcion: {
                        props: {},
                        titulo: 'Estado Subscripcion',
                        componente: EstadoSubscripcion
                    },
                }
            }
        }

        if(soyAdministrador(roles) || soyDistribuidor(roles) || haySubscripcionActiva) {
            componentes = {
                'Usuarios': getPermiso(VER_USUARIOS) ? {
                    opcion: {
                        texto: 'Usuarios',
                        icono: 'fa-user-plus'
                    },
                    pantallas: {
                        usuarios: {
                            props: { roles },
                            titulo: 'Usuarios Plataforma',
                            componente: Usuarios,
                        },
                        form_usuario: {
                            props: { roles },
                            titulo: 'Usuario',
                            componente: EditarUsuario,
                        }
                    }
                } : {},
                'Membresias': getPermiso(VER_MEMBRESIAS) ? {
                    opcion: {
                        texto: 'Membresias',
                        icono: 'fa-crown'
                    },
                    pantallas: {
                        membresias: {
                            props: {},
                            titulo: 'Membresias',
                            componente: Membresias,
                        },
                        form_membresia: {
                            props: {},
                            titulo: 'Membresia',
                            componente: FormMembresia,
                        },
                        subscripciones: {
                            props: {},
                            titulo: 'Subscripciones',
                            componente: Subscripciones,
                        },
                        form_subscripcion: {
                            props: { monedas },
                            titulo: 'Subscripción',
                            componente: FormSubscripcion,
                        },
                    }
                } : {},
                'Facturas': getPermiso(VER_FACTURAS) ? {
                    opcion: {
                        texto: 'Mis Facturas',
                        icono: 'fa-file-invoice'
                    },
                    pantallas: {
                        facturas: {
                            props: { motivosCancelacion: motivosCancelacion },
                            titulo: 'Facturas',
                            componente: PaginaFacturas
                        },
                        generar_factura: {
                            props: {
                                regimenesSociales: regimenesSociales,
                                monedas: monedas,
                                clavesUnidad: clavesUnidad,
                                formasPagos: formasPagos,
                            },
                            titulo: 'Generar Factura',
                            componente: GenerarFactura
                        }
                    }
                } : {},
                'Clientes': getPermiso(VER_RECEPTORES) ? {
                    opcion: {
                        texto: 'Mis Clientes',
                        icono: 'fa-users'
                    },
                    pantallas: {
                        clientes: {
                            props: {},
                            titulo: 'Mis Clientes',
                            componente: PaginaClientes,
                        },
                        form_cliente: {
                            props: {
                                regimenesSociales: regimenesSociales,
                                monedas: monedas,
                                formasPagos: formasPagos,
                            },
                            titulo: 'Cliente',
                            componente: FormCliente
                        },
                        detalle_cliente: {
                            props: { 
                                regimenesSociales: regimenesSociales, 
                                monedas: monedas,
                                formasPagos: formasPagos,
                            },
                            titulo: 'Detalles Cliente',
                            componente: DetalleCliente
                        }
                    }
                } : {},
                'Conceptos': getPermiso(VER_CONCEPTOS) ? {
                    opcion: {
                        texto: 'Mis Conceptos',
                        icono: 'fa-store'
                    },
                    pantallas: {
                        conceptos: {
                            props: { clavesUnidad: clavesUnidad },
                            titulo: 'Mis Conceptos',
                            componente: PaginaConceptos
                        },
                        form_concepto: {
                            props: { clavesUnidad: clavesUnidad, reglasTasaCuota: reglasTasaCuota },
                            titulo: 'Concepto',
                            componente: FormConcepto
                        }
                    }
                } : {},
                ...componentePerfil
            }
        }
        else {
            componentes = {
                'Bloqueado': {
                    opcion: {
                        texto: 'Subscribirse',
                        icono: 'fa-hand-holding-dollar'
                    },
                    pantallas: {
                        facturas: {
                            props: { subscripciones },
                            titulo: 'Renovar Subscripción',
                            componente: Subscribirse
                        }
                    }
                },
                ...componentePerfil
            }
        }

        return (
            <Theme>
                <Navegador
                    themeDefault="dark"
                    empresa={{
                        nombre: "Facturacion EK",
                        imagen: obtenerLogo(usuario?.eureka_cr_multimedia, nouser, 'logo'),
                        version: "v1.0.2-16042024 demo",
                    }}
                    usuario={{
                        nombre: usuario?.nombre,
                        rol: capitalize(obtenerRol(roles)),
                        imagen: obtenerLogo(usuario?.eureka_cr_multimedia, nouser, 'logo'),
                        pantalla: "Perfil"
                    }}
                    componentes={componentes}
                ></ Navegador>
            </Theme>
        )
    }
}