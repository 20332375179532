import { AccountEurekaSigma } from 'account-react-eurekasigma'
import logo from '../../img/eurekasigma_logo.svg'
import { config } from '../../config/config'

const TABLA_USUARIO = 'EurekaAcUsuario'

const InicioSesion = () => {
    const parametrosURL = new URLSearchParams(window.location.search)
    const inactivo = parametrosURL.get('inactivo')

    const configuracion = {
        logo: logo,
        key: config.key,
        tabla: TABLA_USUARIO,
        aceptaRegistros: 0,
        redireccion: `${window.location.origin}/procesar_credenciales`,
        inactivo: inactivo ? inactivo : 0,
        serviciosAccesoMostrar: ['google', 'microsoft'],
        keyAccount: window.location.origin === 'http://localhost:3000' ? config.keyAccount_prueba : config.keyAccount_produccion,
    }

    return (
        <AccountEurekaSigma config={configuracion} />
    )
}

export default InicioSesion