import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { FORM_MEMBRESIA } from './FormMembresia'
import { useObtenerMembresiasQuery } from '../../services/membresia'

const Membresias = ({ ShowAlert, Push }) => {
    const { data, isLoading } = useObtenerMembresiasQuery()

    if(isLoading) return
    else {
        return (
            <Tabla 
                checkbox={false}
                noData="Aún no hay membresias para mostrar"
                busqueda={{ placeholder: "Buscar...", keys: ["razonsocial"] }}
                headers={[
                    { key: 'nombre',      text: 'Nombre',      type: 'text' },
                    { key: 'descripcion', text: 'Descripción', type: 'text' },
                    { key: 'estatus',     text: 'Estatus',     type: 'text' },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Agregar',
                        onClick: () => {
                            Push('form_membresia', {
                                [FORM_MEMBRESIA]: { id: null }
                            })
                        }
                    },
                ]}
                data={
                    buildData(data ?? [], [
                        { key: 'nombre',      type: 'text' },
                        { key: 'descripcion', type: 'text' },
                        { 
                            key: 'estatus',
                            type: 'text',
                            text: (item) => {
                                let estatus = item?.estatus
                                if(estatus === 'activo') {
                                    return <ChipEstatus tipo="Aceptado" texto="Activa" />
                                }
                                else {
                                    return <ChipEstatus tipo="Error" texto="Desactivada" />
                                }
                            },
                            value: (item) => item?.estatus
                        },
                    ])
                }
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            Push('form_membresia', {
                                [FORM_MEMBRESIA]: {
                                    ...item.item
                                }
                            })
                        }
                    },
                    {
                        icono: 'fa-money-bill',
                        tooltip: 'Subscripciones',
                        onClick: (item) => {
                            if(item.item.etiqueta === 'prod_ilimitado') {
                                ShowAlert({
                                    icono: 'warning',
                                    titulo: 'Atención',
                                    mensaje: 'La membresía Ilimitada no puede tener subscripciones',
                                    time: 3,
                                })
                            }
                            else if(item.item.estatus === 'activo') {
                                Push('subscripciones', {
                                    membresiaID: item.item.id,
                                    nombreMembresia: item.item.nombre,
                                    token_stripe_producto: item.item.etiqueta,
                                })
                            }
                            else {
                                ShowAlert({
                                    icono: 'error',
                                    titulo: 'Error',
                                    mensaje: 'Primero active la membresía para poder crear subscripciones.',
                                    time: 3,
                                })
                            }
                        }
                    }
                ]}
            />
        )
    }
}

export default Membresias