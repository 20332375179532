import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux"
import { store } from "./app/store"
import { RouterProvider } from 'react-router-dom'
import { rutas } from './routes'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={rutas} />
  </Provider>
);
