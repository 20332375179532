import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useObtenerSubscripcionesQuery } from "../../services/subcripcion"
import { numberFormat } from '../../js/util'
import { FORM_SUBSCRIPCION } from './FormSubscripcion'
import { useEffect, useState } from 'react'

const Subscripciones = ({ membresiaID, nombreMembresia, token_stripe_producto, Push, UpTittle }) => {
    const [recurrenciasUtilizadas, fijaRecurrenciasutilizadas] = useState([])

    const {data, isLoading} = useObtenerSubscripcionesQuery({
        membresiaID: membresiaID
    })

    useEffect(() => {
        UpTittle(`Membresia ${nombreMembresia}`)

        if(!isLoading) {
            let recurrenciasActivas = data.map((d) => {
                if(d.token_stripe) return d.recurrencia
                else return ''
            })
            fijaRecurrenciasutilizadas(recurrenciasActivas.filter((r) => r !== ''))
        }
    }, [isLoading, data, UpTittle, nombreMembresia])

    if(isLoading) return
    else {
        return (
            <Tabla 
                checkbox={false}
                noData="Aún no hay subscripciones para mostrar"
                busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                headers={[
                    { key: 'nombre',      text: 'Nombre',      type: 'text' },
                    { key: 'precio',      text: 'Precio',      type: 'text' },
                    { key: 'recurrencia', text: 'Recurrencia', type: 'text' },
                    { key: 'estatus',     text: 'Estatus',     type: 'text' },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Agregar',
                        onClick: () => {
                            Push('form_subscripcion', {
                                [FORM_SUBSCRIPCION]: { 
                                    id: null,
                                    membresia: membresiaID,
                                    token_stripe_producto
                                },
                                recurrenciasUtilizadas
                            })
                        }
                    },
                ]}
                data={
                    buildData(data ?? [], [
                        { key: 'nombre', type: 'text' },
                        { 
                            key: 'precio',
                            type: 'text',
                            text: (item) => {
                                return `${numberFormat(item.precio)}/${item.moneda ?? 'MXN'}`
                            },
                            value: (item) => item?.precio
                        },
                        { key: 'recurrencia', type: 'text' },
                        { 
                            key: 'estatus',
                            type: 'text',
                            text: (item) => {
                                if(item.token_stripe) {
                                    return <ChipEstatus tipo="Aceptado" texto="Activa" />
                                }
                                else {
                                    return <ChipEstatus tipo="Error" texto="Archivada" />
                                }
                            },
                        },
                    ])
                }
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            Push('form_subscripcion', {
                                [FORM_SUBSCRIPCION]: { 
                                    ...item.item,
                                    membresia: membresiaID,
                                    token_stripe_producto
                                },
                                recurrenciasUtilizadas
                            })
                        }
                    },
                ]}
            />
        )
    }
}

export default Subscripciones