import { crearProducto } from "./stripe"
import { capitalize } from "./util"

/**
 * 
 * @param {string} nombre 
 * @param {string} descripcion 
 */
export const crearMembresia = async (nombre = '', descripcion = '') => {
    return await crearProducto({
        nombre: nombre,
        descripcion: descripcion,
    })
}

/**
 * 
 * @param {string} nom 
 * @returns 
 */
export const verificarNombre = (nom = '') => {
    let val = ''
    nom = nom.toLowerCase()

    if(nom.includes('membresia')) {
        val = nom.replace('membresia', '').trim()
    }
    else if(nom.includes('plan')) {
        val = nom.replace('plan', '').trim()
    }
    else if(nom.includes('subscripcion')) {
        val = nom.replace('subscripcion', '').trim()
    }
    else if(nom.includes('suscripcion')) {
        val = nom.replace('suscripcion', '').trim()
    }
    else if(nom.includes('suscripción')) {
        val = nom.replace('suscripción', '').trim()
    }
    else val = nom

    return capitalize(val)
}