import { DIA, MIN } from 'account-react-eurekasigma'

export const config = {
    key: 'Cu6iYMYw1dsbV9R7DbZ0TKMvaMJVf8lvigq3PREw',
    urlCRUFDEK: 'https://crud.eksuite.com/api/v2/CRUFDEK',
    urlCRUFDEKArchivo: 'https://crud.eksuite.com/api/GetFile',
    colorPrimario: '#362AD8',
    colorSecundario: '#36A7FF',
    colorBlanco: '#FFF',
    colorNegro: '#000',
    urlTimbrado: window.location.origin === 'http://localhost:3000' ? 
        'https://demo-facturacion.finkok.com/servicios/soap/stamp.wsdl' :
        'https://facturacion.finkok.com/servicios/soap/stamp.wsdl',
    urlCancelacion: window.location.origin === 'http://localhost:3000' ? 
        'https://demo-facturacion.finkok.com/servicios/soap/cancel.wsdl' :
        'https://facturacion.finkok.com/servicios/soap/cancel.wsdl',
    expCookie: { cantidad: 15, tipo: DIA },
    inactividad: { cantidad: 15, tipo: DIA },
    tiempoRefrescar: { cantidad: 50, tipo: MIN },
    urlFacturacion: 'https://invoice.eksuite.com/api/v1/crear_factura',
    urlCancelarFactura: 'https://invoice.eksuite.com/api/v1/cancelar_factura',
    nomCookieUsuario: 'usuario',
    nomCookieToken: 'tokenSesion',
    urlEurekaPagos: 'https://payment.eksuite.com/api/v2/stripe',
    keyEurekaPagos: 'QaHXsbE1O0V3fqeWre6VOJAfbuGYdS31ggD4EW53',
    keySecretaEncriptar: 'Eureka_Facturacion_2024',
    keyAccount_produccion: 'nmjzcAr1QzlrE1E31ljRDG4Tik6FbyYefglIVrea',
    keyAccount_prueba: 'dJqHPT3fr8EEN2e2G6uom27dcQoBc00L5uE7HICM',
}