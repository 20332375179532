import { CardEvento, ModalForm } from 'eureka-design'
import { useEffect, useState } from 'react'
import { recuperarSubscripcionStripe } from '../../js/subscripcion'
import { MESES, numberFormat, obtenerLogo } from '../../js/util'
import nologo from '../../img/no-logo.png'
import { cancelarSubscripcion, recuperarMetodoPago } from '../../js/stripe'
import '../../css/util.css'
import Cargando from '../../components/util/Cargando'

export const FORM_ESTADO_SUBSCRIPCION = 'form_subscripcion'

const RECURRENCIA = {
    week: 'Semanal',
    month: 'Mensual',
    year: 'Anual',
}

const EstadoSubscripcion = ({ form_subscripcion, ShowAlert }) => {
    const [plan, fijaPlan] = useState(null)
    const [metodoPago, fijaMetodoPago] = useState(null)
    const [mostrarModal, cambiarMostrarModal] = useState(false)
    const [cancelando, cambiarEstadoCancelando] = useState(false)
    const [planCancelado, cambiarEstadoPlanCancelado] = useState(false)

    useEffect(() => {
        const obtenerPlan = async () => {
            let res = await recuperarSubscripcionStripe(form_subscripcion?.token_stripe)
            if(res.ok) {
                cambiarEstadoPlanCancelado(res.suscripcion.status !== 'active')
                fijaPlan(res.suscripcion)

                res = await recuperarMetodoPago({ metodoPagoID: res.suscripcion.default_payment_method })
                if(res.ok) fijaMetodoPago(res.metodoPago)
                else fijaMetodoPago({})
            }
            else fijaPlan({})
        }

        obtenerPlan()
    }, [form_subscripcion])

    const cancelarPlan = async () => {
        if(!cancelando) {
            cambiarEstadoCancelando(true)
            let res = await cancelarSubscripcion({ suscripcionID: plan.id })
            fijaPlan(res.cancelacion)
            if(res.ok) {
                cambiarEstadoPlanCancelado(true)
                ShowAlert({
                    icono: 'success',
                    titulo: 'Plan Cancelado',
                    mensaje: 'Sus beneficios continuaran hasta la fecha corte.',
                    time: 2,
                })
            }
            else {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error al Cancelar',
                    mensaje: 'Sucedio un problema al internar cancelar su plan, intente de nuevo.',
                    time: 2,
                })
            }
            cambiarEstadoCancelando(false)
            cambiarMostrarModal(false)
        }
        else {
            ShowAlert({
                icono: 'error',
                titulo: 'Permiso Denegado',
                mensaje: 'Espere un momento en lo que terminamos de cancelar su plan.',
                time: 2,
            })
        }
    }

    if(!plan || !metodoPago) return
    else {
        let fechaRenovacion = new Date(plan.current_period_end * 1000)
        let fechaUltPago = new Date(plan.current_period_start * 1000)
        let estadoFecha = plan.status === 'active' ? 'Fecha de Renovación' : 'Los Beneficios Terminan'
        // console.log(plan)
        
        return (
            <>
                <ModalForm
                    titulo="ATENCIÓN"
                    show={mostrarModal}
                    botones={[
                        {
                            texto: "Si, Quiero Cancelar",
                            onClick: cancelarPlan,
                            iconLeft: "fa-ban",
                        }
                    ]}
                    onClose={() => { cambiarMostrarModal(false) }}
                >   
                    {
                        cancelando ?
                            <Cargando mensaje="Cancelando plan, por favor espere..." /> :
                            <div className="div-flex-colum">
                                <i className="fa fa-circle-info icono-info"></i>
                                <p className="texto-centrado">
                                    ¡Espera un momento! Estás a punto de cancelar tu suscripción. Queremos recordarte que seguirás disfrutando de todos los beneficios hasta la fecha de corte.
                                </p>
                                <h3 className="texto-centrado">¿Seguro que quieres cancelar ahora?</h3>
                            </div>
                    }
                </ModalForm>

                <CardEvento 
                    imagen={obtenerLogo(form_subscripcion?.eureka_pa_subscripcione?.eureka_me_membresia?.eureka_cr_multimedia, nologo)}
                    titulo={`Plan ${form_subscripcion?.eureka_pa_subscripcione?.nombre ?? 'Sin Nombre'}`}
                    fecha={
                        `${estadoFecha}: ${fechaRenovacion.getDate()} de ${MESES[fechaRenovacion.getMonth()]} de ${fechaRenovacion.getFullYear()}`
                    }
                    extras={[
                        { 
                            icono: 'fa-calendar', 
                            texto: `Último Pago: ${fechaUltPago.getDate()} de ${MESES[fechaUltPago.getMonth()]} de ${fechaUltPago.getFullYear()}` 
                        },
                        { 
                            icono: 'fa-dollar', 
                            texto: `${RECURRENCIA[plan.plan.interval]} ${plan.currency.toUpperCase()} ${numberFormat(plan.plan.amount / 100)}` 
                        },
                        { 
                            icono: 'fa-credit-card', 
                            texto: `Metodo de Pago Preferido: ${metodoPago.card.brand.toUpperCase()} que termina en ${metodoPago.card.last4}` 
                        },
                        { 
                            icono: 'fa-circle-info', 
                            texto: form_subscripcion?.eureka_pa_subscripcione?.eureka_me_membresia?.descripcion 
                        },
                    ]}
                    opciones={[
                        planCancelado ? {
                            icono: "fa-power-off",
                            tooltip: "Renovar Plan",
                            onClick: () => { 
                                console.log('renovar plan...')
                            }
                        } : { 
                            icono: 'fa-power-off', 
                            tooltip: 'Cancelar Plan',
                            onClick: () => { cambiarMostrarModal(true) }
                        }
                    ]}
                />
            </>
        )
    }
}

export default EstadoSubscripcion