import { Formulario, Input, Select, InputSearch } from 'eureka-design'
import { useEffect, useState } from 'react'
import {
    CATALOGO_CLAVES_PRODUCTOS,
    CATALOGO_IMPUESTOS,
    CATALOGO_OBJETOS_IMPUESTO,
    // CATALOGO_TIPOS_FACTORES,
    IEPS,
    OBJETO_IIMPUESTO,
    adaptarCatalogoSAT,
    obtenerCatalogoSAT,
    obtenerValor
} from '../../js/factura'
import { useCrearConceptoMutation, useCrearProductoMutation } from '../../services/concepto'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

export const FORM_PRODUCTO = 'form_producto'
export const FORM_CONCEPTO = 'form_concepto'

const FormConcepto = ({ UpProps, form_concepto, form_producto, clavesUnidad, reglasTasaCuota }) => {
    const usuario = infoUsuario()

    const [productoID, fijaProductoID] = useState(form_producto?.id)
    const [conceptoID, fijaConceptoID] = useState(form_concepto?.id)

    const [cargandoCatalogos, cambiarEstadoCargandoCatalogos] = useState(true)
    const [objetosImpuesto, fijaObjetosImpuesto] = useState([])
    const [impuestos, fijaImpuesto] = useState([])
    // const [factores, fijaFactores] = useState([])
    const [clavesProducto, fijaClavesProducto] = useState([])

    const [valorTasaCuota, fijaValorTasaCuota] = useState(String(form_concepto?.impuesto_valor ?? '0.00'))
    const [desactivarValorTasaCuota, cambiarEstadoDesactivarValorTasaCuota] = useState(false)

    const [productoMutation,{
        isLoading: actualizandoProducto,
        isSuccess: productoActualizado,
        isError: productoErroneo,
    }] = useCrearProductoMutation()
    const [conceptoMutation, {
        isLoading: actualizandoConcepto,
        isSuccess: conceptoActualizado,
        isError: conceptoErroneo,
    }] = useCrearConceptoMutation()

    useEffect(() => {
        const cargarCatalogosSAT = async () => {
            fijaObjetosImpuesto(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_OBJETOS_IMPUESTO), true))
            fijaImpuesto(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_IMPUESTOS), true))
            // fijaFactores(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_TIPOS_FACTORES), true))
            cambiarEstadoCargandoCatalogos(false)
        }

        if(cargandoCatalogos) cargarCatalogosSAT()
        // else ya se cargaron los catalogos del SAT

        // console.log(form_concepto)
        // console.log(form_producto)
    }, [cargandoCatalogos])

    const crearProducto = async (nom, val, id = null) => {
        return await productoMutation({
            id: id ? id : productoID,
            body: {
                [nom]: val,
                usuario: usuario.id,
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let creadoID = res.mensaje.id

                fijaProductoID(creadoID)
                handlerFormData(FORM_PRODUCTO, creadoID, nom, val)
                return creadoID
            }
            else return productoID
        })
    }

    const crearConcepto = async (nom, val, productoID) => {
        return await conceptoMutation({
            id: conceptoID,
            body: {
                [nom]: val,
                nombre_unidad: 'Tasa',
                producto: productoID,
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let creadoID = res.mensaje.id

                handlerFormData(FORM_CONCEPTO, creadoID, nom, val)
                fijaConceptoID(creadoID)
                return creadoID
            }
            else return conceptoID
        })
    }

    const buscarProducto = async (val) => {
        fijaClavesProducto(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_CLAVES_PRODUCTOS, true, val), true, 'value'))
    }

    const cambiarEntrada = async (val, nom) => {
        if(nom === 'clave_sat' || nom === 'descripcion' || nom === 'clave' ) {
            if(nom === 'clave_sat') {
                let id = await crearProducto(nom, val.id)
                handlerFormData(FORM_PRODUCTO, productoID, nom, val.id)
                crearProducto('nombre', val.texto, id)
                handlerFormData(FORM_PRODUCTO, id, 'nombre', val.texto)
            }
            else {
                crearProducto(nom, val)
                if(productoID || form_producto?.id) handlerFormData(FORM_PRODUCTO, productoID, nom, val)
            }
        }
        else {
            let id = null
            if(productoID) id = productoID
            else id = await crearProducto('nombre', '')

            if(val !== '') {
                crearConcepto(nom, val, id)
                if(conceptoID || form_concepto?.id) handlerFormData(FORM_CONCEPTO, conceptoID, nom, val)
            }
            // else no actualizar el registro
        }
    }

    const calcularValorTasaCuota = (nom, obj, id) => {
        let tipoImpuesto = null
        let tipoTasaCuota = null
        if(nom === 'nombre_unidad') {
            tipoImpuesto = obtenerValor(impuestos, form_concepto.impuesto_clave, null, true)?.texto
            tipoTasaCuota = 'Tasa'
        }
        else if(nom === 'impuesto_clave') {
            tipoImpuesto = obj?.item?.texto ?? obj?.item?.id
            tipoTasaCuota = 'Tasa'
        }

        let reglas = reglasTasaCuota.filter((regla) => {
            return regla.impuesto === tipoImpuesto && regla.factor === tipoTasaCuota
        })

        let valor = 0
        if(reglas.length === 0) {
            valor = String('0.00')
            cambiarEstadoDesactivarValorTasaCuota(false)
        }
        else if(reglas.length === 1) {
            valor = String(reglas[0].valor)
            cambiarEstadoDesactivarValorTasaCuota(true)
        }
        else {
            valor = reglas.reduce((max, regla) => (parseFloat(regla.valor) > max ? parseFloat(regla.valor) : max), 0)

            valor = String(valor)
            if(tipoImpuesto === IEPS) cambiarEstadoDesactivarValorTasaCuota(false)
            else cambiarEstadoDesactivarValorTasaCuota(true)
        }

        fijaValorTasaCuota(valor)
        crearConcepto('impuesto_valor', valor, id)
        if(conceptoID || form_concepto?.id) handlerFormData(FORM_CONCEPTO, conceptoID, 'impuesto_valor', valor)
    }

    const cambiarSelector = async (obj, nom) => {
        let id = null
        if(productoID) id = productoID
        else id = await crearProducto('nombre', '')

        if(nom === 'impuesto_tipo') {
            if(obj.id === OBJETO_IIMPUESTO) {
                document.getElementById('infoImpuesto').style.display = 'grid'
            }
            else {
                document.getElementById('infoImpuesto').style.display = 'none'
            }
        }
        // else no verificar si ocultar el apartado de impuestos


        if(nom === 'nombre_unidad' || nom === 'impuesto_clave') {
            calcularValorTasaCuota(nom, obj, id)
        }
        // else no calcular el valor de tasa o cuota

        crearConcepto(nom, obj.id, id)
        if(conceptoID || form_concepto?.id) handlerFormData(FORM_CONCEPTO, conceptoID, nom, obj.id)
    }

    const handlerFormData = (form, id, key, val) => {
        let formSeleccionado = (form === FORM_CONCEPTO) ? form_concepto : form_producto

        UpProps({
            [form]: {
                ...formSeleccionado,
                id: id,
                [key]: val
            }
        })
    }

    if(cargandoCatalogos) return
    else {
        return (
            <Formulario
                titulo="Concepto"
                isLoading={[actualizandoProducto, actualizandoConcepto]}
                isSuccess={[productoActualizado, conceptoActualizado]}
                isError={[productoErroneo, conceptoErroneo]}
            >
                <InputSearch
                    nombre="clave_sat"
                    placeholder="Buscar clave de hacienda..."
                    opciones={clavesProducto}
                    changeFunction={buscarProducto}
                    value={form_producto?.nombre}
                    OpcionSelect={(val) => {
                        cambiarEntrada(val, 'clave_sat')
                    }}
                />
                <Input
                    type="text"
                    nombre="clave"
                    placeholder="Nombre Personalizado"
                    iconLeft="fa-tag"
                    changeFunction={cambiarEntrada}
                    value={form_producto?.clave}
                />
                <Input
                    type="textarea"
                    nombre="descripcion"
                    placeholder="Descripcion"
                    changeFunction={cambiarEntrada}
                    value={form_producto?.descripcion}
                    iconLeft="fa-file-lines"
                />
                <Select
                    placeholder="Objeto Impuesto"
                    iconLeft="fa-file-invoice-dollar"
                    options={objetosImpuesto}
                    value={obtenerValor(objetosImpuesto, form_concepto?.impuesto_tipo, 'Seleccione un objeto de impuesto...')}
                    nombre="impuesto_tipo"
                    changeFunction={cambiarSelector}
                />
                <Select
                    iconLeft="fa-boxes-stacked"
                    options={clavesUnidad}
                    value={obtenerValor(clavesUnidad, form_concepto?.clave_unidad, 'Seleccione una clave de unidad...')}
                    nombre="clave_unidad"
                    changeFunction={cambiarSelector}
                    busqueda={{ placeholder: 'Clave Unidad' }}
                />
                <div
                    id="infoImpuesto"
                    style={{
                        display: form_concepto?.impuesto_tipo === OBJETO_IIMPUESTO ? 'grid' : 'none',
                        gap: '10px',
                    }}
                >
                        <Select
                            placeholder="Impuesto"
                            iconLeft="fa-circle-dollar-to-slot"
                            options={impuestos}
                            value={obtenerValor(impuestos, form_concepto?.impuesto_clave, 'Seleccione una clave de impuesto...')}
                            nombre="impuesto_clave"
                            changeFunction={cambiarSelector}
                        />
                        {/* <Select
                            placeholder="¿Tasa o Cuota?"
                            iconLeft="fa-circle-dollar-to-slot"
                            options={factores}
                            value={obtenerValor(factores, form_concepto?.nombre_unidad, '')}
                            nombre="nombre_unidad"
                            changeFunction={cambiarSelector}
                        /> */}
                        <Input
                            type="text"
                            nombre="impuesto_valor"
                            placeholder="Valor Impuesto"
                            value={valorTasaCuota}
                            changeFunction={cambiarEntrada}
                            iconLeft="fa-circle-dollar-to-slot"
                            disabled={desactivarValorTasaCuota}
                        />
                </div>
                <Input
                    type="number"
                    max={999}
                    nombre="cantidad"
                    placeholder="Cantidad (Inicial)"
                    value={form_concepto?.cantidad}
                    changeFunction={cambiarEntrada}
                    iconLeft="fa-hashtag"
                />
                <Input
                    type="number"
                    max={999999}
                    nombre="valor_unitario"
                    placeholder="Valor Unitario"
                    value={form_concepto?.valor_unitario}
                    changeFunction={cambiarEntrada}
                    iconLeft="fa-dollar-sign"
                />
            </Formulario>
        )
    }
}

export default FormConcepto