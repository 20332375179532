import { createBrowserRouter } from 'react-router-dom'
import { NotFound } from 'eureka-design'
// import Index from '../pages/layouts'
import InicioSesion from '../pages/procesamiento/InicioSesion'
import ProcesarCredenciales from '../pages/procesamiento/ProcesarCredenciales'
import TerminarSesion from '../pages/procesamiento/TerminarSesion'
import Inicio from '../pages/Inicio'

export const rutas = createBrowserRouter([
    {
        path: '/',
        element: <Inicio/>,
        errorElement: <NotFound />
    },
    {
        path: '/iniciar_sesion',
        element: <InicioSesion />,
        errorElement: <NotFound />
    },
    {
        path: '/procesar_credenciales',
        element: <ProcesarCredenciales />,
        errorElement: <NotFound />
    },
    {
        path: '/cerrar_sesion',
        element: <TerminarSesion />,
        errorElement: <NotFound />
    },
]) 