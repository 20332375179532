import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_PRODUCTO = 'EurekaStProducto'
const TABLA_CONCEPTOS = 'EurekaFaConcepto'
const TABLA_EXTRA_PRODUCTO = 'EurekaStProductosExtra'

export const conceptoAPI = createApi({
    reducerPath: 'conceptoAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Conceptos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerPrductos: builder.query({
            /**
             * 
             * @param {{ usuarioID: int }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_PRODUCTO)
                            .with('eureka_fa_conceptos')
                            .with('eureka_st_productos_extras')
                            .where('usuario', '=', data.usuarioID)
                            .get()
                }
            },
        }),
        crearProducto: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTO)
                if (data?.id) {
                    body = body.where("id", data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearConcepto: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CONCEPTOS)
                if (data?.id) {
                    body = body.where("id", data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearExtrasProducto: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_EXTRA_PRODUCTO)
                if(data?.id) {
                    body = body.where('id', data.id).update(data.body)
                }
                else {
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        })
    }),
})

export const { 
    useObtenerPrductosQuery,
    useCrearProductoMutation,
    useCrearConceptoMutation,
    useCrearExtrasProductoMutation,
} = conceptoAPI