import { Tabla, buildData } from 'eureka-design'
import { useObtenerPrductosQuery } from '../../services/concepto'
import { FORM_CONCEPTO, FORM_PRODUCTO } from './FormConcepto'
import { obtenerValor } from '../../js/factura'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

const PaginaConceptos = ({ Push, clavesUnidad }) => {
    const usuario = infoUsuario()

    const { data, isLoading } = useObtenerPrductosQuery({ usuarioID: usuario?.id })

    if(isLoading) return
    else {
        return (
            <Tabla
                checkbox={false}
                noData="Aún no hay conceptos para mostrar"
                busqueda={{ placeholder: 'Buscar...', keys: ['nombre'] }}
                headers={[
                    { key: 'clave',        text: 'Nombre Personalizado',  type: 'text' },
                    { key: 'clave_sat',    text: 'Clave',                 type: 'text' },
                    { key: 'nombre',       text: 'Nombre SAT',            type: 'text' },
                    { key: 'clave_unidad', text: 'Unidad',                type: 'text' },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Agregar',
                        onClick: () => {
                            Push('form_concepto', {
                                [FORM_PRODUCTO]: { id: null },
                                [FORM_CONCEPTO]: { id: null },
                            })
                        }
                    }
                ]}
                data={
                    buildData(data ?? [], [
                        { 
                            key: 'clave', 
                            type: 'text', 
                            text: (item) => {
                                return item?.clave ?? 'Sin Nombre'
                            }
                        },
                        { 
                            key: 'clave_sat', 
                            type: 'text', 
                            text: (item) => {
                                return item?.clave_sat ?? 'Sin Clave'
                            }
                        },
                        { 
                            key: 'nombre',
                            type: 'text', 
                            text: (item) => {
                                return item?.nombre ?? 'Sin nombre SAT'
                            }
                        },
                        { 
                            key: 'clave_unidad', 
                            type: 'text',
                            text: (item) => {
                                if(item.eureka_fa_conceptos.length === 0) return 'Sin Unidad'
                                else return obtenerValor(clavesUnidad ?? [], item.eureka_fa_conceptos[0]?.clave_unidad, '') ?? 'Sin Unidad'
                            }
                        },
                    ])
                }
                onClickRow={(item) => {
                    let conceptos = item.item.eureka_fa_conceptos
                    Push('form_concepto', {
                        [FORM_PRODUCTO]: { ...item.item },
                        [FORM_CONCEPTO]: (conceptos.length === 0) ? {} : { ...conceptos[0] }
                    })
                }}
            >
            </Tabla>
        )
    }
}

export default PaginaConceptos