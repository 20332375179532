import { Input, Select, DropFile, ModalForm } from 'eureka-design'
import { obtenerValor } from '../../js/factura'
import '../../css/modal.css'
import { useState } from 'react'
import Cargando from '../util/Cargando'

const contenidoFlex =  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
}

const SeccionFormConnect = ({
    tokenStripe = '',
    form_perfil_connect = {}, 
    paises = [], 
    monedas = [],
    cambiarEntrada = () => {},
    cambiarSelector = () => {},
    guardarIdentificacion = () => {},
    handlerCrearPerfil = () => {},
    mostrarModal = false,
    cambiarEstadoMostrarModal = () => {},
    guardandoPerfilStripe = false,
}) => {
    const [existeTokenStripe] = useState(tokenStripe?.length > 0)

    return (
        <>
            <ModalForm
                titulo="ATENCIÓN"
                show={mostrarModal}
                onClose={() => { cambiarEstadoMostrarModal(false) }}
                botones={[
                    {
                        texto: (existeTokenStripe) ? "Editar Perfil" : "Crear Perfil",
                        onClick: () => { handlerCrearPerfil(existeTokenStripe ? 'editar' : 'crear') },
                        iconLeft: "fa-user-tie",
                    }
                ]}
            >
                <div className="contenido-modal">
                    <div className="contenido-modal__mensaje">
                        {   (guardandoPerfilStripe) ? 
                                <Cargando mensaje="Guardando su información, por favor espere..." /> :
                                (existeTokenStripe) ?
                                    <>
                                        <p className="contenido-modal__icono">
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                        </p>
                                        <p>¿Desea editar sus datos de cuenta bancaría?</p>
                                    </> :
                                    <>
                                        <p className="contenido-modal__icono">
                                            <i className="fa-solid fa-circle-exclamation"></i>
                                        </p>
                                        <p>
                                            Es la primera vez que vas a crear tu perfil de distribuidor. 
                                            Por favor verifica la información proporcionada, 
                                            ya que una vez guardada y verificada no podrás editar esta información por 
                                            cuestiones de seguridad a excepción de los datos de tu cuenta bancaria e información fiscal.
                                        </p>
                                        <br />
                                        <p>¿Desea continuar con la creación?</p>
                                    </>
                        }
                    </div>
                </div>
            </ModalForm>

            <br />
            <h3>Información de Distribuidor</h3>
            <div style={contenidoFlex}>
                <Input 
                    iconLeft="fa-file-signature"
                    placeholder="Nombre(s)"
                    nombre="nombre"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.nombre}
                    disabled={existeTokenStripe}
                />
                <Input 
                    iconLeft="fa-file-signature"
                    placeholder="Apellido(s)"
                    nombre="apellidos"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.apellidos}
                    disabled={existeTokenStripe}
                />
            </div>
            <div style={contenidoFlex}>
                <Input 
                    type="tel"
                    iconLeft="fa-phone"
                    placeholder="Teléfono"
                    nombre="telefono"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.telefono}
                    disabled={existeTokenStripe}
                />
                <Input 
                    type="date"
                    iconLeft="fa-calendar-days"
                    placeholder="Fecha de Nacimiento"
                    nombre="nacimiento"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.nacimiento}
                    disabled={existeTokenStripe}
                />
            </div>
            <Select 
                iconLeft="fa-globe"
                options={paises}
                nombre="pais_empresa"
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'País' }}
                value={obtenerValor(paises, form_perfil_connect?.pais_empresa, 'Seleccione un pais...')}
            />
            <div style={contenidoFlex}>
                <Input 
                    iconLeft="fa-earth-americas"
                    placeholder="Estado" 
                    nombre="estado_empresa"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.estado_empresa}
                    disabled={existeTokenStripe}
                />
                <Input 
                    iconLeft="fa-city"
                    placeholder="Ciudad"
                    nombre="ciudad_empresa"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.ciudad_empresa}
                    disabled={existeTokenStripe}
                />
            </div>
            <div style={contenidoFlex}>
                <Input 
                    iconLeft="fa-location-dot"
                    placeholder="Domicilio"
                    nombre="direccion1_empresa"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.direccion1_empresa}
                    disabled={existeTokenStripe}
                />
                <Input 
                    iconLeft="fa-map-pin"
                    placeholder="Código Postal"
                    nombre="cp_empresa"
                    changeFunction={cambiarEntrada}
                    value={form_perfil_connect.cp_empresa}
                    disabled={existeTokenStripe}
                />
            </div>

            <h3>
                <i className="fa-solid fa-id-card"></i>
                {` Identificación Oficial (Parte Frontal)`}
            </h3>
            <DropFile 
                nombre="identificacion_frontal"
                allowedExtensions={['.png', '.jpg', 'jpeg']}
                onChange={guardarIdentificacion}
            />
            <h3>
                <i className="fa-solid fa-id-card"></i>
                {` Identificación Oficial (Parte Trasera)`}
            </h3>
            <DropFile 
                nombre="identificacion_trasera"
                allowedExtensions={['.png', '.jpg', 'jpeg']}
                onChange={guardarIdentificacion}
            />
            
            <br />
            <h3>Información Bancaria</h3>
            <Input 
                iconLeft="fa-money-check-dollar"
                placeholder="N. Cuenta Bancaria"
                nombre="numero_cuenta"
                changeFunction={cambiarEntrada}
                value={form_perfil_connect.numero_cuenta}
                disabled={existeTokenStripe}
            />
            <Select 
                iconLeft="fa-coins"
                options={monedas}
                nombre="moneda"
                changeFunction={cambiarSelector}
                busqueda={{ placeholder: 'Moneda Cuenta' }}
                value={obtenerValor(monedas, form_perfil_connect?.moneda, 'Seleccione una moneda...')}
            />
        </>
    )
}

export default SeccionFormConnect