import { Formulario, Input, ImageInput } from 'eureka-design'
import { useState } from 'react'
import { obtenerLogo } from '../../js/util'
import nologo from '../../img/no-logo.png'
import { crearMembresia, verificarNombre } from '../../js/membresia'
import { useCrearMembresiaMutation, useGuardarLogoMembresiaMutation } from '../../services/membresia'

export const FORM_MEMBRESIA = 'form_membresia'

const FormMembresia = ({ UpProps, ShowAlert, monedas, form_membresia }) => {
    const [membresiaID, fijaMembresiaID] = useState(form_membresia?.id)
    const [logo, fijaLogo] = useState(form_membresia?.logo)

    const [crearMembresiaMutation, {
        isLoading: editandoMembresia,
        isError: membresiaErronea,
        isSuccess: membresiaEditada,
    }] = useCrearMembresiaMutation()
    const [guardarLogoMutation, {
        isLoading: editandoLogo,
        isError: logoErroneo,
        isSuccess: logoEditado,
    }] = useGuardarLogoMembresiaMutation()

    const handlerMembresia = async (nom, val) => {
        return await crearMembresiaMutation({
            id: membresiaID,
            body: { [nom]: val }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaMembresiaID(id)
                return id
            }
            else {
                return membresiaID
            }
        })
    }

    const handlerLogo = async (e) => {
        handlerState('urlLogo', URL.createObjectURL(e.target.files[0]))

        return await guardarLogoMutation({
            id: logo?.id,
            configFile: { file: e.target.files[0], carpeta: 'logo', nombre: 'nombre' }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let logoCreado = res.mensaje
                fijaLogo(logoCreado)
                handlerMembresia('logo', logoCreado.id)
                return logoCreado
            }
            else {
                return logo
            }
        })
    }

    const handlerInput = async (val, nom) => {
        val = nom === 'nombre' ? verificarNombre(val) : val
        handlerMembresia(nom, val)
        handlerState(nom, val)
    }

    const handlerState = (nom, val, id = null, dataExtra = {}) => {
        UpProps({ 
            [FORM_MEMBRESIA]: {
                ...form_membresia,
                id: id ? id : membresiaID, 
                [nom]: val,
                ...dataExtra,
            }
        })
    }

    const handlerMembresiaStripe = async () => {
        let nombre = form_membresia.nombre
        let descripcion = form_membresia.descripcion

        if(nombre && descripcion) {
            let res = await crearMembresia(nombre, descripcion)
            if(res.ok) {
                handlerMembresia('etiqueta', res.producto.id)
                handlerMembresia('estatus', 'activo')
                handlerState('etiqueta', res.producto.id, membresiaID, {
                    'estatus': 'activo'
                })

                ShowAlert({
                    icono: 'success',
                    titulo: 'Guardado Correctamente',
                    mensaje: 'Ahora se pueden crear subscripciones para esta membresía.',
                    time: 3,
                })
            }
            else {
                ShowAlert({
                    icono: 'error',
                    titulo: 'Error',
                    mensaje: res.mensaje,
                    time: 3,
                })
            }
        }
        else {
            ShowAlert({
                icono: 'error',
                titulo: 'Error',
                mensaje: 'Verifique que haya llenado todos los datos para poder crear la membresía.',
                time: 3,
            })
        }
    }

    return (
        <Formulario
            titulo={form_membresia?.nombre ? `Editar Membresia ${form_membresia.nombre}` : "Crear Membresía"}
            isLoading={[editandoMembresia, editandoLogo]}
            isError={[membresiaErronea, logoErroneo]}
            isSuccess={[membresiaEditada, logoEditado]}
            botones={!form_membresia?.etiqueta ? [
                {
                    texto: 'Guardar Membresía',
                    onClick: handlerMembresiaStripe,
                    iconLeft: "fa-gear",
                }
            ] : []}
        >
            <ImageInput 
                width={200}
                height={200}
                avatar={true}
                preview={form_membresia?.urlLogo ?? obtenerLogo(form_membresia?.logo, nologo)}
                changeFunction={handlerLogo}
            />
            <Input 
                nombre="nombre"
                placeholder="Nombre"
                iconLeft="fa-signature"
                value={form_membresia?.nombre}
                changeFunction={handlerInput}
            />
            <Input 
                type="textarea"
                nombre="descripcion"
                placeholder="Descripción"
                iconLeft="fa-file-lines"
                value={form_membresia?.descripcion}
                changeFunction={handlerInput}
            />
        </Formulario>
    )
}

export default FormMembresia