import { LoaderCircle } from 'eureka-design'

const Cargando = ({ mensaje = 'Cargando, por favor espere...' }) => {
    return (
        <>
            <LoaderCircle size="large"></LoaderCircle>
            <h2 style={{ textAlign: 'center' }}>
                {mensaje}
            </h2>
        </>         
    )
}

export default Cargando