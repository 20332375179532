import { CardEvento } from 'eureka-design'
import nouser from '../../img/nouser.jpg'
import { useState } from 'react'
import { MESES } from '../../js/util'
import { obtenerValor } from '../../js/factura'

const DetalleCliente = ({ cliente, regimenesSociales, monedas, formasPagos }) => {
    const [fecha] = useState(new Date())

    return (
        <CardEvento
            imagen={nouser}
            fecha={`${fecha.getDate()} de ${MESES[fecha.getMonth()]} del ${fecha.getFullYear()}`}
            titulo={cliente?.razonsocial ?? 'Sin Razón Social'}
            extras={[
                { icono: 'fa-id-card', texto: cliente?.idfiscal ?? 'Sin RFC' },
                { icono: 'fa-user-tie', texto: obtenerValor(regimenesSociales, cliente?.regimen, 'Sin Regimen Fiscal') },
                { icono: 'fa-house-user', texto: cliente?.codigopostal ?? 'Sin Domicilio Fiscal' },
                { icono: 'fa-circle-dollar-to-slot', texto: obtenerValor(monedas, cliente?.moneda, 'Sin Moneda Preferida') },
                { icono: 'fa-money-check-dollar', texto: obtenerValor(formasPagos, cliente?.forma_pago, 'Sin Forma de Pago') },
            ]}
        />
    )
}

export default DetalleCliente