import { Tabla, buildData } from 'eureka-design'
import { useObtenerReceptoresQuery } from '../../services/receptor'
import { FORM_CLIENTE } from './FormCliente'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

const PaginaClientes = ({ Push }) => {
    const usuario = infoUsuario()

    const { data, isLoading } = useObtenerReceptoresQuery({ usuarioID: usuario.id })

    if(isLoading) return
    else {
        return (
            <Tabla
                checkbox={false}
                noData="Aún no hay clientes para mostrar"
                busqueda={{ placeholder: "Buscar...", keys: ["razonsocial"] }}
                headers={[
                    { key: 'razonsocial',  text: 'Nombre',           type: 'text' },
                    { key: 'idfiscal',     text: 'RFC',              type: 'text' },
                    { key: 'regimen',      text: 'Regimen Fiscal',   type: 'text' },
                    { key: 'codigopostal', text: 'Domicilio Fiscal', type: 'text' },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Agregar',
                        onClick: () => { 
                            Push('form_cliente', { 
                                [FORM_CLIENTE]: { id: null } 
                            }) 
                        }
                    },
                ]}
                data={
                    buildData(data ?? [], [
                        { key: 'razonsocial',  type: 'text' },
                        { key: 'idfiscal',     type: 'text' },
                        { key: 'regimen',      type: 'text' },
                        { key: 'codigopostal', type: 'text' },
                    ])
                }
                onClickRow={(item) => {
                    Push('detalle_cliente', { 
                        cliente: { ...item.item } 
                    })
                }}
                botonesRow={[
                    {
                        icono: 'fa-solid fa-file-edit',
                        tooltip: 'Editar',
                        onClick: (item) => {
                            Push('form_cliente', { 
                                [FORM_CLIENTE]: { ...item.item } 
                            })
                        }
                    },
                ]}
            >
            </Tabla>
        )
    }
}

export default PaginaClientes