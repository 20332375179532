import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_MEMBRESIAS = 'EurekaMeMembresia'
const TABLA_SUBSCRIPCIONES = 'EurekaPaSubscripcione'
export const TABLA_SUBSCRIPTORES = 'EurekaPaSubscriptore'
const TABLA_TARJETAS = 'EurekaPaTarjeta'

export const subscripcionAPI = createApi({
    reducerPath: 'subscripcionAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Subscripcion'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerSubscripciones: builder.query({
            /**
             * 
             * @param {{ membresiaID: int }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_SUBSCRIPCIONES)
                if(data?.membresiaID) body = body.where('membresia', data.membresiaID).get()
                else body = body.where('nombre', 'Ilimitado Prueba').get()

                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearSubscripcion: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_SUBSCRIPCIONES)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerSubscripcionesUsuario: builder.query({
            /**
             * 
             * @param {{ usuarioID: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_SUBSCRIPTORES)
                            .where('usuario', data.usuarioID)
                            .with('eureka_pa_subscripcione.eureka_me_membresia.eureka_cr_multimedia')
                            .get()
                }
            }
        }),
        crearSubscriptor: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_SUBSCRIPTORES)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerMembresiasActivas: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_MEMBRESIAS)
                            .where('estatus', 'activo')
                            .with('eureka_cr_multimedia')  
                            .with('eureka_pa_subscripciones')
                            .get()
                }
            },
            transformErrorResponse: () => {
                window.location.reload()
            },
            transformResponse: (data) => {
                data = data.filter((membresia) => {
                    let tieneSubscripciones = membresia.eureka_pa_subscripciones.length > 0
                    
                    if(tieneSubscripciones) {
                        let subscripcionesConPrecio = membresia.eureka_pa_subscripciones.filter((subscripcion) => {
                            return parseFloat(subscripcion.precio) > 0
                        })
                        return subscripcionesConPrecio.length > 0
                    }
                    else return false
                })
                return data
            },
        }),
        obtenerTarjetas: builder.query({
            /**
             * 
             * @param {{ usuarioID: int }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_TARJETAS)
                            .where('usuario', data.usuarioID)
                            .get()
                }
            }
        }),
        crearTarjeta: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_TARJETAS)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    })
})


export const {
    useObtenerSubscripcionesQuery,
    useCrearSubscriptorMutation,
    useObtenerSubscripcionesUsuarioQuery,
    useCrearSubscripcionMutation,
    useObtenerMembresiasActivasQuery,
    useObtenerTarjetasQuery,
    useCrearTarjetaMutation,
} = subscripcionAPI