import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_RECEPTOR = 'EurekaPaCuentaFiscal'

export const receptorAPI = createApi({
    reducerPath: 'receptorAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Receptores'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerReceptores: builder.query({
            /**
             * 
             * @param {{ usuarioID: int }} data 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_RECEPTOR)
                            .where('usuario', '=', data.usuarioID)
                            .where('estatus', '=', 'aprobada')
                            .get()
                }
            }
        }),
        crearReceptor: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_RECEPTOR)
                if(data.id) 
                    body = body.where('id', data.id).update(data.body)
                else 
                    body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const {
    useObtenerReceptoresQuery,
    useCrearReceptorMutation,
} = receptorAPI