import { Formulario, Select, Input, ModalForm, ProgressBar } from 'eureka-design'
import { useEffect, useState } from 'react'
import SeccionReceptor from '../../components/facturas/SeccionReceptor'
import SeccionComprobante from '../../components/facturas/SeccionComprobante'
import SeccionConceptos from '../../components/facturas/SeccionConceptos'
import { MESES } from '../../js/util'
import { 
    obtenerCatalogoSAT, 
    adaptarCatalogoSAT, 
    timbrarFactura,
    CATALOGO_IMPUESTOS,
    CATALOGO_USOS_FACTURA,
    CATALOGO_TIPOS_RELACIONES,
    CATALOGO_METODOS_PAGOS,
    CATALOGO_TIPOS_COMPROBANTES,
    obtenerValor,
    abrirArchivo,
    crearArchivoBlob,
} from '../../js/factura'
import { useObtenerReceptoresQuery } from '../../services/receptor'
import { useObtenerPrductosQuery } from '../../services/concepto'
import { useObtenerEmisorQuery } from '../../services/emisor'
import { useCrearArchivoFacturaMutation, useCrearFacturaMutation, useEditarArchivoFacturaMutation } from '../../services/factura'
import { useObtenerArchivosQuery } from '../../services/usuario'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

export const FORM_FACTURA = 'form_factura'

const GenerarFactura = ({ UpTittle, ShowAlert, UpProps, form_factura, regimenesSociales, monedas, clavesUnidad, formasPagos }) => {
    const usuario = infoUsuario()

    const [fecha] = useState(new Date())
    const [progesoTimbrado, cambiaProgresoTimbrado] = useState(0)
    const [mostrarModalTimbrado, cambiaEstadoModalTimbrado] = useState(false)
    const [sePuedeCerrarModalTimbrado, cambiaEstadoCerrarModalTimbrado] = useState(false)
    const [urlFactura, fijaURLFactura] = useState(form_factura?.urlFactura ?? '')

    const [facturaID, fijaFacturaID] = useState(form_factura?.id)
    const [receptor, fijaReceptor] = useState(form_factura?.eureka_pa_cuenta_fiscal)
    const [conceptosAgregados, fijaConceptosAgregados] = useState(form_factura?.eureka_fa_conceptos)

    const { data: emisor, isLoading: cargandoInformacion } = useObtenerEmisorQuery({ 
        id: usuario?.eureka_pa_cuenta_fiscals[0]?.id
    })
    const { data: archivos, isLoading: cargandoArchivos } = useObtenerArchivosQuery({
        usuarioID: usuario?.id
    })
    const { data: receptores, isLoading: cargandoReceptores } = useObtenerReceptoresQuery({
        usuarioID: usuario?.id
    })
    const { data: conceptos, isLoading: cargandoConceptos } = useObtenerPrductosQuery({
        usuarioID: usuario?.id
    })

    const [cargandoCatalogos, cambiarEstadoCargandoCatalogos] = useState(true)
    const [impuestos, fijaImpuestos] = useState(null)
    const [usosFactura, fijaUsosFactura] = useState(null)
    const [tiposRelaciones, fijaTiposRelaciones] = useState(null)
    const [metodosPagos, fijaMetodosPagos] = useState(null)
    const [tiposFacturas, fijaTiposFacturas] = useState(null)

    const [crearFactura, {
        isLoading: actualizandoFactura,
        isError: facturaErronea,
        isSuccess: facturaActualizada
    }] = useCrearFacturaMutation()
    const [editarArchivoMutation] = useEditarArchivoFacturaMutation()
    const [crearArchivoMutation] = useCrearArchivoFacturaMutation()

    useEffect(() => {
        const cargarCatalogosSAT = async () => {
            fijaImpuestos(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_IMPUESTOS), true))
            fijaUsosFactura(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_USOS_FACTURA), true))
            fijaTiposRelaciones(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_TIPOS_RELACIONES), true))
            fijaMetodosPagos(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_METODOS_PAGOS), true))
            fijaTiposFacturas(adaptarCatalogoSAT(await obtenerCatalogoSAT(CATALOGO_TIPOS_COMPROBANTES)))
            cambiarEstadoCargandoCatalogos(false)
        }

        if(cargandoCatalogos) cargarCatalogosSAT()
        // else ya se cargaron los catalogos del SAT

        UpTittle('Generar CFDI 4.0')
        // console.log(form_factura)
    }, [cargandoCatalogos, cambiarEstadoCargandoCatalogos, UpTittle])

    const generarRegistroBaseFactura = (id = null) => {
        let fechaCreacion = null
        let folio = null
        let formatoFechaCreacion = null

        if(form_factura?.fecha_creada) fechaCreacion = new Date(form_factura?.fecha_creada)
        else fechaCreacion = new Date()

        formatoFechaCreacion = `${fechaCreacion.getFullYear()}-${fechaCreacion.getMonth()+1}-${fechaCreacion.getDate()}`
        folio = `${fechaCreacion.getFullYear()}${fechaCreacion.getMonth()+1}${fechaCreacion.getDate()}${id ?? ''}`

        return {
            usuario_emisor: usuario?.id,
            moneda: form_factura?.moneda ?? receptor?.moneda ?? null,
            forma_pago: form_factura?.forma_pago ?? receptor?.forma_pago ?? null,
            fecha_creada: formatoFechaCreacion,
            folio: folio,
            timbrada: 'factura',
            estatus: 'programada',
        }
    }

    const crearRegistroFactura = async (nom, val, id = null) => {
        id = id ? id : facturaID

        return await crearFactura({
            id: id,
            body: {
                ...generarRegistroBaseFactura(id),
                [nom]: val
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                fijaFacturaID(res.mensaje.id)
                return res.mensaje.id
            }
            else {
                return facturaID
            }
        })
    }

    const ligarArchivoTimbrado = async (nom, val, tipo = 'pdf') => {
        return await editarArchivoMutation({
            id: tipo === 'pdf' ? form_factura?.pdf : form_factura?.xml,
            body: {
                nombre: tipo === 'pdf' ? 'factura.pdf' : 'cfdi.xml',
                carpeta: tipo,
                tipo: tipo === 'pdf' ? tipo : 'archivo',
                [nom]: val
            }
        })
        .unwrap()
        .then(async (res) => {
            if(res?.mensaje?.id) {
                await crearRegistroFactura(tipo, res.mensaje.id)
                return res.mensaje.id
            }
            //else ya debe estar enlazado a la factura
        })
    }

    const crearArchivoFactura = async (archivo = null, carpeta = 'xml') => {
        return await crearArchivoMutation({
            id: carpeta === 'xml' ? form_factura?.xml : form_factura?.pdf,
            configFile: { file: archivo, carpeta: carpeta, nombre: 'nombre' }
        })
        .unwrap()
        .then(async (res) => {
            if(res?.mensaje?.id) {
                await crearRegistroFactura(carpeta, res.mensaje.id)
                return res.mensaje.id
            }
            //else ya debe estar enlazado a la factura
        })
    }

    const generarFactura = async () => {
        cambiaProgresoTimbrado(0)
        if(urlFactura !== '') {
            ShowAlert({
                icono: 'error',
                titulo: 'Factura Timbrada',
                mensaje: 'La factura se a timbrado, por lo cual esta acción no se permite más de una vez',
            })
        }
        else {
            cambiaEstadoModalTimbrado(true)
            let intervaloID = setInterval(() => {
                cambiaProgresoTimbrado(progesoTimbrado => {
                    if(progesoTimbrado >= 100) {
                        clearInterval(intervaloID)
                        return progesoTimbrado
                    }
                    else return progesoTimbrado + 1
                })
            }, 500)

            let data = await timbrarFactura(form_factura, emisor, receptor, conceptosAgregados, clavesUnidad, archivos, ShowAlert)
            if(data) {
                fijaURLFactura(data.urlFactura)

                let formatoFecha = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
                await crearRegistroFactura('uuid', data.UUID)
                await crearRegistroFactura('fecha_emitida', formatoFecha)
                await crearRegistroFactura('fecha_timbrada', formatoFecha)
                await ligarArchivoTimbrado('archivo', data.urlFactura, 'pdf')
                await crearArchivoFactura(crearArchivoBlob(data.cfdi, 'text/xml'), 'xml')
                await crearRegistroFactura('estatus', 'timbrada')

                handlerFormData(null, null, facturaID, {
                    uuid: data.UUID,
                    fecha_emitida: formatoFecha,
                    fecha_timbrada: formatoFecha,
                    estatus: 'timbrada',
                    urlFactura: data.urlFactura,
                })

                cambiaEstadoCerrarModalTimbrado(true)
                ShowAlert({
                    icono: 'success',
                    titulo: 'Factura Timbrada',
                    mensaje: 'Tu factura esta lista para visualizar y descargar',
                    time: 5,
                })
            }
            else {
                cambiaEstadoModalTimbrado(false)
            }
            cambiaProgresoTimbrado(100)
        }
    }

    const cerrarModalProgreso = () => {
        if(sePuedeCerrarModalTimbrado) {
            cambiaEstadoModalTimbrado(false)
        }
        else {
            ShowAlert({
                icono: 'warning',
                titulo: 'Timbrando Factura',
                mensaje: 'Por favor espere a que se termine de generar su factura',
                time: 3,
            })
        }
    }

    const mostrarFactura = () => {
        if(urlFactura !== '') {
            abrirArchivo(urlFactura)
        }
        else {
            ShowAlert({
                icono: 'warning',
                titulo: 'Timbrando Factura',
                mensaje: 'Por favor espere a que se termine de generar su factura o vuelva a intentarlo',
                time: 5,
            })
        }
    }

    const cambiarReceptor = async (seleccionado) => {
        fijaReceptor(seleccionado)
        
        let nombres = seleccionado.razonsocial.split(' ')
        let serie = ''
        for(let i = 0; i < nombres.length; i++) serie += nombres[i][0]

        let id = await crearRegistroFactura('receptor', seleccionado.id)
        await crearRegistroFactura('serie', serie, id)

        handlerFormData(null, null, id, {
            receptor: seleccionado.id,
            serie: serie,
            eureka_pa_cuenta_fiscal: seleccionado,
        })
    }

    const actualizarConceptosAgregados = (conceptos) => {
        fijaConceptosAgregados(conceptos)
        handlerFormData(null, null, facturaID, {
            eureka_fa_conceptos: conceptos
        })
    }

    const onChangeEntrada = async (val, nom) => {
        let id = null

        if(nom === 'tipo_factura') {
            id = await crearRegistroFactura(nom, val.text)
            handlerFormData(nom, val.text, id)
        }
        else if(val instanceof Object) {
            id = await crearRegistroFactura(nom, val.id)
            handlerFormData(nom, val.id, id)
        }
        else {
            id = await crearRegistroFactura(nom, val)
            handlerFormData(nom, val, id)
        }
    }

    const handlerFormData = (key, val, id = null, formData = null) => {
        id = id === null ? facturaID : id
        formData = formData === null ? 
            {
                ...form_factura,
                ...generarRegistroBaseFactura(id)
            }: 
            {
                ...form_factura,
                ...generarRegistroBaseFactura(id),
                ...formData
            }

        UpProps({ 
            [FORM_FACTURA]: {
                ...formData,
                id: id, 
                [key]: val
            }
        })
    }
    
    if(cargandoCatalogos || cargandoInformacion || cargandoReceptores || cargandoConceptos || cargandoArchivos) return
    else {
        return (
            <>
                <ModalForm
                    titulo="Timbrando Factura"
                    show={mostrarModalTimbrado}
                    onClose={cerrarModalProgreso}
                    botones={[
                        {
                            texto: "Abrir Factura",
                            onClick: mostrarFactura,
                            iconLeft: "fa-file-arrow-down",
                        }
                    ]}
                >
                    <ProgressBar progress={progesoTimbrado} size="medium" hard={true} />
                </ModalForm>

                <Formulario
                        titulo={`${fecha.getDate()} de ${MESES[fecha.getMonth()]} del ${fecha.getFullYear()}`}
                        botones={[
                            { 
                                texto: 'Timbrar', 
                                iconLeft: 'fas fa-file-invoice', 
                                onClick: generarFactura 
                            }
                        ]}
                        isSuccess={[facturaActualizada]}
                        isError={[facturaErronea]}
                        isLoading={[actualizandoFactura]}
                >
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Input value={form_factura?.serie} placeholder="Serie" disabled={true} iconLeft="fa-font" />
                            <Input value={form_factura?.folio} placeholder="Folio" disabled={true} iconLeft="fa-hashtag" />
                        </div>
                        <Select 
                            placeholder="Tipo de Factura"
                            nombre="tipo_factura"
                            iconLeft="fa-file-invoice"
                            options={tiposFacturas}
                            value={
                                obtenerValor(
                                    tiposFacturas, 
                                    form_factura?.tipo_factura?.length > 0 ? form_factura?.tipo_factura[0] : 'I', 
                                    'Seleccione un tipo de factura...'
                                )
                            }
                            changeFunction={onChangeEntrada}
                        />

                        <SeccionReceptor
                            titulo="Datos del Receptor"
                            receptores={receptores}
                            usosFactura={usosFactura}
                            regimenSociales={regimenesSociales}
                            onChangeReceptor={cambiarReceptor}
                            onChangeEntrada={onChangeEntrada}
                            formFactura={form_factura}
                            receptor={receptor}
                        />

                        <SeccionComprobante 
                            titulo="Comprobante"
                            monedas={monedas}
                            formasPagos={formasPagos}
                            metodosPagos={metodosPagos}
                            tiposRelaciones={tiposRelaciones}
                            onChangeEntrada={onChangeEntrada}
                            formFactura={form_factura}
                            receptor={receptor}
                        />
                        
                        <SeccionConceptos
                            titulo="Conceptos"
                            conceptos={conceptos}
                            conceptosAgregados={conceptosAgregados}
                            impuestos={impuestos}
                            clavesUnidad={clavesUnidad}
                            actualizarConceptosAgregados={actualizarConceptosAgregados}
                            crearFactura={crearRegistroFactura}
                            facturaID={facturaID}
                            usuario={usuario}
                        />
                </Formulario>
        </>
        )
    }
}

export default GenerarFactura