import { Formulario, Input, Select } from 'eureka-design'
import { useEffect, useState } from 'react'
import { ESTATUS_USUARIOS, capitalize } from '../../js/util'
import { useCrearRolMutation, useCrearUsuarioMutation, useObtenerRolesQuery } from '../../services/usuario'
import { useCrearSubscriptorMutation, useObtenerSubscripcionesQuery } from '../../services/subcripcion'
import { handler_rol } from '../../js/handler_rol'
import { DAR_MEMBRESIA, DAR_ROL } from '../../js/roles'
import SeccionChips from '../../components/SeccionChips'

export const FORM_USUARIO = 'form_usuario'

const EditarUsuario = ({ UpTittle, UpProps, form_usuario, roles }) => {
    const [getPermiso] = handler_rol(roles)

    const [fecha] = useState(new Date())
    const [usuarioID, fijaUsuarioID] = useState(form_usuario?.id)
    const [rolesUsuario, fijaRolesUsuario] = useState(form_usuario?.eureka_cr_rol_usuarios ?? [])
    const [subscriptorID, fijaSubscriptorID] = useState(form_usuario?.subscriptorID)

    const { data: rolesPlataforma, isLoading: cargandoRoles } = useObtenerRolesQuery()
    const { data: subscripciones, isLoading: cargandoSubscripciones } = useObtenerSubscripcionesQuery()
    
    const [
        crearUsuarioMutation,
        {
            isLoading: actualizandoUsuario,
            isError: usuarioErroneo,
            isSuccess: usuarioActualizado,
        }
    ] = useCrearUsuarioMutation()
    const [
        crearRolMutation,
        {
            isLoading: actualizandoRolUsuario,
            isError: rolUsuarioErroneo,
            isSuccess: rolUsuarioActualizado,
        }
    ] = useCrearRolMutation()
    const [
        crearSubscriptorMutation,
        {
            isLoading: actualizandoSubscriptor,
            isSuccess: subscriptorActualizado,
            isError: subscriptorErroneo,
        }
    ] = useCrearSubscriptorMutation()

    useEffect(() => {
        UpTittle(form_usuario?.nombre ?? 'Registrar Usuario')
    })

    const crearUsuario = async (nom, val) => {
        let fechaRegistro = ''
        if(form_usuario?.fecha_registro) {
            let iFinal = form_usuario.fecha_registro.indexOf('T')
            fechaRegistro = form_usuario.fecha_registro.substring(0, iFinal)
        }
        else {
            fechaRegistro = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
            handlerFormData('fecha_registro', fechaRegistro)
        }

        return await crearUsuarioMutation({
            id: usuarioID,
            body: {
                fecha_registro: fechaRegistro,
                estatus: form_usuario?.estatus ? form_usuario.estatus : 'inactivo',
                [nom]: val,
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaUsuarioID(id)
                handlerFormData(nom, val, id)
                return id
            }
            else {
                handlerFormData(nom, val, usuarioID)
                return usuarioID
            }
        })
    }

    const crearRol = async (nom, val, usuario = null, relacionRolID = null) => {
        return await crearRolMutation({
            id: relacionRolID,
            body: { 
                [nom]: val,
                usuario: usuario,
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                return res.mensaje
            }
            else {
                return null
            }
        })
    }

    const crearSubscriptor = async (nom, val, usuario = null) => {
        return await crearSubscriptorMutation({
            id: subscriptorID,
            body: {
                usuario: usuario ? usuario : usuarioID,
                subscripcion: subscripciones[0].id,
                [nom]: val,
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaSubscriptorID(id)
                return id
            }
            else {
                return subscriptorID
            }
        })
    }

    const handlerEntrada = async (val, nom) => {
        if(val instanceof Object) {
            let id = null, relacion = null

            if(nom === 'membresia') {
                id = await crearUsuario('foto', '')
                relacion = await crearSubscriptor(
                    'estatus',
                    val.id === 0 ? 'inactivo' : 'activo',
                    id
                )
                handlerFormData(nom, val.text, id, {
                    'subscriptorID': relacion,
                })
            }
            else if(nom === 'rol') {
                id = await crearUsuario('foto', '')
                relacion = await crearRol(nom, val.id, id)
                handlerFormData(nom, val.text, id, {
                    'rolID': relacion,
                })
            }
            else {
                crearUsuario(nom, val.text) 
            }
        }
        else {
            await crearUsuario(nom, val)
        }
    }

    const handlerChipRol = async (cambiarEstadoChip, rol) => {
        let rolesUsuarioCopia = [...rolesUsuario]
        let encontrados = rolesUsuario.filter((rolUsuario) => {
            return rolUsuario.rol === rol.id
        })

        if(encontrados.length > 0) {
            let rolEncontrado = encontrados[0]
            await crearRol('usuario', null, null, rolEncontrado.id)
            rolesUsuarioCopia = rolesUsuarioCopia.filter((rolUsuario) => {
                return rolUsuario.rol !== rol.id
            })
            cambiarEstadoChip(false)
        }
        else {
            let id = await crearUsuario('foto', '')
            let rolCreado = await crearRol('rol', rol.id, id)
            rolCreado = { ...rolCreado }
            Object.preventExtensions(rolCreado)
            rolCreado.get_rol = rol
            rolesUsuarioCopia.push(rolCreado)
            cambiarEstadoChip(true)
        }
        fijaRolesUsuario(rolesUsuarioCopia)
    }
    
    const handlerFormData = (key, val, id = null, extras = null) => {
        id = id === null ? usuarioID : id
        extras = extras === null ? {} : extras

        UpProps({ 
            [FORM_USUARIO]: {
                ...form_usuario,
                id: id,
                ...extras,
                [key]: val
            }
        })
    }

    if(cargandoRoles || cargandoSubscripciones) return
    else {
        // console.log(form_usuario)
        let seccionChips = getPermiso(DAR_ROL) ? 
            <SeccionChips 
                titulo="Roles de Usuario"
                datos={rolesPlataforma}
                colTexto="nombre"
                callbackSelect={(cambiarEstadoChip, item) => {
                    handlerChipRol(cambiarEstadoChip, item)
                }}
                datosSeleccionados={rolesUsuario}
                colDatosSeleccionados="rol"
            /> : null

        return (
            <Formulario
                titulo="Editar Usuario"
                isLoading={[actualizandoUsuario, actualizandoRolUsuario, actualizandoSubscriptor]}
                isSuccess={[usuarioActualizado, rolUsuarioActualizado, subscriptorActualizado]}
                isError={[usuarioErroneo, rolUsuarioErroneo, subscriptorErroneo]}
            >
                <Input 
                    nombre="nombre"
                    placeholder="Nombre"
                    iconLeft="fa-pen"
                    value={form_usuario.nombre}
                    changeFunction={handlerEntrada}
                />
                <Input 
                    nombre="telefono"
                    placeholder="Telefono"
                    iconLeft="fa-phone"
                    value={form_usuario.telefono}
                    changeFunction={handlerEntrada}
                />
                <Input 
                    nombre="correo"
                    placeholder="Correo"
                    iconLeft="fa-at"
                    value={form_usuario.correo}
                    changeFunction={handlerEntrada}
                />
                <Select
                    nombre="estatus" 
                    placeholder="Estatus"
                    iconLeft="fa-user-gear"
                    options={ESTATUS_USUARIOS}
                    value={form_usuario?.estatus ? capitalize(form_usuario.estatus) : 'Seleccione un estatus...'}
                    changeFunction={handlerEntrada}
                />
                {
                    getPermiso(DAR_MEMBRESIA) ? 
                        <Select
                            nombre="membresia" 
                            placeholder="Otorgar Membresia Ilimitada"
                            iconLeft="fa-crown"
                            options={[ 
                                { id: 0, text: 'NO' },
                                { id: 1, text: 'SI' },
                            ]}
                            value={form_usuario?.membresia ?? 'Seleccione una respuesta...'}
                            changeFunction={handlerEntrada}
                        /> : null
                }
                {seccionChips}            
            </Formulario>
        )
    }
}

export default EditarUsuario