import eurekaSigmaLogo from '../../img/eurekasigma.svg'
import eurekaSigmaIcono from '../../img/eurekasigma_logo.svg'
import '../../css/subscripcion.css'
import InfoMembresia from './InfoMembresia'
import { useState } from 'react'
import { numberFormat } from '../../js/util'
import { ModalForm, Button } from 'eureka-design'

const VentanaSinSubscripcion = ({ membresias = [], handlerSubscribirse = () => {} }) => {
    const [mostrarSubscripciones, fijaMostrarSubscripciones] = useState(false)
    const [membresia, fijaMembresia] = useState(null)

    const handlerMostrarSubscripciones = (membresia) => {
        fijaMembresia(membresia)
        fijaMostrarSubscripciones(true)
    }

    return (
        <>
            <ModalForm
                titulo={`Plan(es) disponible(s) para ${membresia?.nombre ?? 'sin nombre'}`}
                show={mostrarSubscripciones}
                onClose={() => { fijaMostrarSubscripciones(false) }}
            >
                {
                    membresia?.eureka_pa_subscripciones?.map((subscripcion, index) => {
                        if(subscripcion.token_stripe?.length > 0) {
                            return (
                                <div className="card-subscripcion" key={index}>
                                    <p className="ventana-subscripcion__contenido__precio ">
                                        {
                                            (subscripcion) ?
                                                `${subscripcion?.moneda}${numberFormat(subscripcion?.precio)}/${subscripcion?.recurrencia}` :
                                                'Espere a que haya una subscripción disponible'
                                        }
                                    </p>
                                    <Button texto="Empezar a Timbrar" onClick={() => { 
                                        handlerSubscribirse(subscripcion)
                                        fijaMostrarSubscripciones(false)
                                    }} />
                                </div>
                            )
                        }
                        else return null
                    })
                }
            </ModalForm>

            <div className="ventana-subscripcion">
                <div className="ventana-subscripcion__contenido">
                    <img className="ventana-subscripcion__contenido__logo " src={eurekaSigmaLogo} alt="logo" />
                    
                    <p className="ventana-subscripcion__contenido__descripcion">¡Únete a nuestra comunidad y aprovecha al máximo tu experiencia de facturación!</p>
                    <p className="ventana-subscripcion__contenido__descripcion">Con nuestra suscripción premium, tendrás acceso completo a nuestra potente plataforma de timbrado de facturas. ¡Almacenar tus conceptos y clientes nunca ha sido tan fácil! Además, podras descargar tanto el PDF como el CFDI de tus timbrados en un abrir y cerrar de ojos.</p>
                    <p className="ventana-subscripcion__contenido__descripcion">No pierdas más tiempo y únete ahora para disfrutar de todas estas increíbles ventajas.</p>
                    <p className="ventana-subscripcion__contenido__descripcion">¡Subscríbete hoy y lleva tu facturación al siguiente nivel!</p>
                </div>
                <img className="ventana-subscripcion__icono" src={eurekaSigmaIcono} alt="icono" />
            </div>
            <br />
            <div className="seccion-membresias">
                {
                    membresias.map((membresia, index) => {
                        return (
                            <InfoMembresia 
                                key={index} 
                                membresia={membresia}
                                handlerSubscripciones={handlerMostrarSubscripciones}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export default VentanaSinSubscripcion