import { Chip } from 'eureka-design'
import { capitalize } from '../js/util'

const ICONO_CHIP = 'fa-user-tag'

const SeccionChips = ({
    icono = 'fa-id-badge',
    titulo = 'Sección', 
    datos = [], 
    colTexto = 'nombre',
    datosSeleccionados = [],
    colDatosSeleccionados = 'id',
    callbackSelect = () => {} 
}) => {
    const estaSeleccionado = (item) => {
        let seleccionados = datosSeleccionados.filter((seleccionado) => {
            return seleccionado[colDatosSeleccionados] === item.id
        })
        return seleccionados.length > 0
    }

    return (
        <>
            <h3><i className={`fa ${icono}`}></i>{` ${titulo}`}</h3>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {
                    datos.map((item, index) => {
                        let seleccionado = estaSeleccionado(item)

                        return (
                            <Chip 
                                key={index}
                                texto={capitalize(item[colTexto])}
                                icono={ICONO_CHIP}
                                onClick={(cambiarEstadoChip) => { callbackSelect(cambiarEstadoChip, item) }}
                                selected={seleccionado}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export default SeccionChips