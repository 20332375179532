import { Tabla, buildData, ModalForm, Folio, FolioInfo } from 'eureka-design'
import { useState } from 'react'
import { 
    CATALOGO_CLAVES_PRODUCTOS, 
    OBJETO_IIMPUESTO, 
    calcularImporte, 
    calcularTotal, 
    calcularValorImpuesto, 
    obtenerCatalogoSAT, 
    obtenerValor 
} from '../../js/factura'
import { numberFormat } from '../../js/util'
import { useCrearConceptoFacturaMutation } from '../../services/factura'

const parrafoDesglose = {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: 30,
    fontWeight: 'normal'
}

const SeccionConceptos = ({ 
    titulo = '', 
    conceptos = [],
    conceptosAgregados = [],
    impuestos = [], 
    clavesUnidad = [], 
    actualizarConceptosAgregados = () => {},
    facturaID = null,
    crearFactura = async () => {},
    usuario = null
}) => {
    const estiloTextoCentrado = { textAlign: 'center', margin: '15px 0' }
    const [mostrarModalConceptos, cambiarEstadoModalConceptos] = useState(false)
    const [mostrarModalConcepto, cambiarEstadoModalConcepto] = useState(false)
    const [conceptosSeleccionados, fijaConceptosSeleccionados] = useState(conceptosAgregados)
    const [conceptoSeleccionado, fijaConceptoSeleccionado] = useState(null)

    const [crearConceptoFacturaMutation] = useCrearConceptoFacturaMutation()
    const [conceptosFiltrados, fijaConceptosFiltrados] = useState(conceptos)

    const crearConcepto = async (id, body, eliminar = false) => {
        return await crearConceptoFacturaMutation({
            id: id,
            body: body
        })
        .unwrap()
        .then((res) => {
            let conceptosActualizados = []
            if(res?.mensaje?.id) {
                conceptosActualizados = [ ...conceptosSeleccionados ]
                conceptosActualizados.push(res.mensaje)
            }
            else {
                conceptosActualizados = conceptosSeleccionados.map((concepto) => {
                    if(concepto.id === id) {
                        if(!eliminar)
                            return {
                                ...concepto,
                                ...body
                            }
                        return undefined
                    }
                    else return concepto
                })
                conceptosActualizados = conceptosActualizados.filter((concepto) => concepto !== undefined)
            }
            actualizarConceptosAgregados(conceptosActualizados)
            fijaConceptosSeleccionados(conceptosActualizados)
            return true
        })
    }

    const abrirModalConceptos = () => { cambiarEstadoModalConceptos(true) }

    const examinarConcepto = async (concepto) => {
        let producto = await obtenerCatalogoSAT(CATALOGO_CLAVES_PRODUCTOS, true, concepto.item.clave_producto)
        producto = producto.length > 0 ? producto[0] : null
        concepto = {
            ...concepto.item,
            producto_nombre: producto.texto
        }

        fijaConceptoSeleccionado(concepto)
        cambiarEstadoModalConcepto(true)
    }

    const agregarConcepto = async (producto) => {
        let conceptos = producto?.item?.eureka_fa_conceptos
        let concepto = conceptos.length === 0 ? null : conceptos[0]

        let id = null
        if(facturaID) id = facturaID
        else id = await crearFactura('usuario_emisor', usuario.id)

        let conceptoExistente = conceptosSeleccionados.filter((concepto) => {
            return (concepto.clave_producto === producto.item.clave_sat)
        })

        let conceptoExistenteID
        let cantidad
        if(conceptoExistente?.length > 0) {
            conceptoExistenteID = conceptoExistente[0].id
            cantidad = conceptoExistente[0].cantidad + 1
        }
        else {
            conceptoExistenteID = null
            cantidad = concepto.cantidad
        }

        crearConcepto(conceptoExistenteID, {
            clave_producto: producto.item.clave_sat,
            cantidad: cantidad,
            clave_unidad: concepto.clave_unidad,
            descripcion: producto.item.descripcion,
            descuento: concepto.descuento,
            factura: id,
            impuesto_clave: concepto.impuesto_clave,
            impuesto_tipo: concepto.impuesto_tipo,
            impuesto_valor: concepto.impuesto_valor,
            nombre_unidad: concepto.nombre_unidad,
            valor_unitario: concepto.valor_unitario,
        })
    }

    const actualizarConcepto = (val, nom, row) => {
        crearConcepto(row.id, { [nom]: val })
    }

    const quitarConcepto = (concepto) => {
        crearConcepto(concepto.id, { factura: null }, true)
    }

    const buscarConcepto = (val = '') => {
        if(val.length === 0) {
            fijaConceptosFiltrados(conceptos)
        }
        else if(val.length > 2) {
            fijaConceptosFiltrados(
                conceptosFiltrados.filter((concepto) => {
                    return (
                        concepto?.clave?.toLowerCase().includes(val.toLowerCase()) ||
                        concepto?.clave_sat?.toLowerCase().includes(val.toLowerCase()) ||
                        concepto?.nombre?.toLowerCase().includes(val.toLowerCase())
                    )
                })
            )
        }
        // else no actualizar los conceptos
    }
    
    return (
        <div>
            <ModalForm
                titulo="Selección de Conceptos"
                show={mostrarModalConceptos}
                onClose={() => { cambiarEstadoModalConceptos(false) }}
            >
                <Tabla 
                    noData="Sin conceptos para seleccionar"
                    checkbox={false}
                    busqueda={{ 
                        value: '',
                        fn: (val) => { buscarConcepto(val) },
                        placeholder: 'Busca concepto...',
                    }}
                    headers={[
                        { key: 'clave',        text: 'Nombre',     type: 'text' },
                        { key: 'clave_sat',    text: 'Clave',      type: 'text' },
                        { key: 'nombre',       text: 'Nombre SAT', type: 'text' },
                        { key: 'clave_unidad', text: 'Unidad',     type: 'text' },
                    ]}
                    data={
                        buildData(conceptosFiltrados, [
                            { key: 'clave',     type: 'text' },
                            { key: 'clave_sat', type: 'text' },
                            { key: 'nombre',    type: 'text' },
                            { 
                                key: 'clave_unidad', 
                                type: 'text',
                                text: (item) => {
                                    if(item.eureka_fa_conceptos.length === 0) return 'Sin Unidad'
                                    else return obtenerValor(clavesUnidad ?? [], item.eureka_fa_conceptos[0]?.clave_unidad, '') ?? 'Sin Unidad'
                                }
                            },
                        ])
                    }
                    onClickRow={agregarConcepto}
                />
            </ModalForm>

            <ModalForm
                titulo={`${conceptoSeleccionado?.clave_producto} - ${conceptoSeleccionado?.producto_nombre}`}
                show={mostrarModalConcepto}
                onClose={() => { cambiarEstadoModalConcepto(false) }}
            >
                <Folio>
                    <FolioInfo titulo="Descripción">
                        <p>{conceptoSeleccionado?.descripcion}</p>
                    </FolioInfo>
                    <FolioInfo titulo="Clave de Unidad">
                        <p>{obtenerValor(clavesUnidad, conceptoSeleccionado?.clave_unidad, '')}</p>
                    </FolioInfo>
                    <FolioInfo titulo="Desglose">
                        <h4 style={parrafoDesglose}>
                            <b>Valor Unitario:</b> <span>{numberFormat(conceptoSeleccionado?.valor_unitario, 2)}</span>
                        </h4>
                        <h4 style={parrafoDesglose}>
                            <b>Cantidad:</b> <span>{conceptoSeleccionado?.cantidad}</span>
                        </h4>
                        <h4 style={parrafoDesglose}>
                            <b>Descuento:</b> <span>{conceptoSeleccionado?.descuento ?? 0}%</span>
                        </h4>
                        <h4 style={parrafoDesglose}>
                            <b>Importe:</b> <span>{numberFormat(calcularImporte(conceptoSeleccionado))}</span>
                        </h4>
                        {
                            conceptoSeleccionado?.impuesto_tipo === OBJETO_IIMPUESTO ?
                                <h3 style={parrafoDesglose}>
                                    <b>Impuesto {obtenerValor(impuestos, conceptoSeleccionado?.impuesto_clave, '')} ({conceptoSeleccionado?.impuesto_valor}%):</b> 
                                    <span>{numberFormat(calcularValorImpuesto(conceptoSeleccionado))}</span>
                                </h3> : null
                        }
                    </FolioInfo>
                    <FolioInfo>
                        <h2 style={parrafoDesglose}>
                            <b>Total:</b> <b>{numberFormat(calcularTotal(conceptoSeleccionado))}</b>
                        </h2>
                    </FolioInfo>
                </Folio>
            </ModalForm>

            <h3 style={estiloTextoCentrado}>{ titulo }</h3>
            <Tabla 
                noData="No hay conceptos seleccionados"
                checkbox={false}
                headers={[
                    { key: 'clave_producto', text: 'Clave',          type: 'text'   },
                    { key: 'valor_unitario', text: 'Unitario',       type: 'precio' },
                    { key: 'cantidad',       text: 'Cantidad',       type: 'number' },
                    { key: 'dscuento',       text: 'Descuento',      type: 'number' },
                    { key: 'importe',        text: 'Importe',        type: 'number' },
                    { key: 'valor_impuesto', text: 'Valor Impuesto', type: 'number' },
                    { key: 'total',          text: 'Total',          type: 'precio' },
                ]}
                botonesHead={[
                    {
                        icono: 'fa-solid fa-plus',
                        tooltip: 'Agregar',
                        onClick: abrirModalConceptos
                    }
                ]}
                data={
                    buildData(
                        conceptosSeleccionados, 
                        [
                            { key: 'clave_producto', type: 'text' },
                            { 
                                key: 'valor_unitario', 
                                type: 'precio', 
                                text: (item) => {
                                    return numberFormat(item.valor_unitario ?? 0)
                                }
                            },
                            { 
                                key: 'cantidad',
                                type: 'number',
                                input: {
                                    type: 'number',
                                    nombre: 'cantidad',
                                    onEnter: actualizarConcepto
                                },
                                text: (item) => {
                                    return parseInt(item.cantidad ?? 0)
                                },
                                value: (item) => {
                                    return parseInt(item.cantidad ?? 0)
                                }
                            },
                            {
                                key: 'descuento',
                                type: 'number',
                                input: {
                                    type: 'number',
                                    nombre: 'descuento',
                                    max: 100,
                                    onEnter: actualizarConcepto
                                },
                                text: (item) => {
                                    return parseInt(item.descuento ?? 0)
                                },
                                value: (item) => {
                                    return parseInt(item.descuento ?? 0)
                                }
                            },
                            {
                                key: 'importe',
                                type: 'number',
                                text: (item) => {
                                    return numberFormat(calcularImporte(item))
                                },
                                value: (item) => {
                                    return numberFormat(calcularImporte(item))
                                }
                            },
                            { 
                                key: 'valor_impuesto',
                                type: 'number',
                                text: (item) => {
                                    return numberFormat(calcularValorImpuesto(item))
                                },
                                value: (item) => {
                                    return numberFormat(calcularValorImpuesto(item))
                                }
                            },
                            {
                                key: 'total',
                                type: 'precio',
                                text: (item) => {
                                    return numberFormat(calcularTotal(item))
                                }
                            },
                        ]
                    )
                }
                onClickRow={examinarConcepto}
                botonesRow={[
                    {
                        icono: 'fa-solid fa-trash',
                        tooltip: 'Quitar',
                        onClick: quitarConcepto
                    }
                ]}
            />
        </div>
    )
}

export default SeccionConceptos