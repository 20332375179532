import { MiPerfil } from 'eureka-design'
import nologo from '../../img/no-logo.png'
import { useObtenerEmisorQuery } from '../../services/emisor'
import { useEffect, useState } from 'react'
import { obtenerLogo, obtenerMiArchivo } from '../../js/util'
import { abrirArchivo, obtenerURLObjeto, obtenerValor } from '../../js/factura'
import { FORM_PERFIL } from './EditarPerfil'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config/config'
import { useObtenerLogoQuery } from '../../services/usuario'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { recuperarDistribuidorStripe } from '../../js/stripe'
import { handler_rol } from '../../js/handler_rol'
import { CREAR_LINK, CREAR_PERFIL_CONNECT, VER_ESTADO_SUBSCRIPCION } from '../../js/roles'
import AES from 'crypto-js/aes'
import { FORM_ESTADO_SUBSCRIPCION } from '../subscripciones/EstadoSubscripcion'
import { obtenerSubscripcion } from '../../js/subscripcion'

const Perfil = ({ Push, ShowAlert, regimenesSociales, roles, subscripciones }) => {
    const navegacion = useNavigate()
    const usuario = infoUsuario()
    const [getPermiso] = handler_rol(roles)
    
    const [cargandoPerfilStripe, estadoCargandoPerfilStripe] = useState(true)
    const [perfilStripe, fijaPerfilStripe] = useState(null)
    const [cuentaFiscalID] = useState(usuario.eureka_pa_cuenta_fiscals[0]?.id)
    const [certificado] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'certificado'))
    const [clavePrivada] = useState(obtenerMiArchivo(usuario.eureka_cr_multimedia ?? [], 'clave_privada'))
    const [subscripcionActiva] = useState(obtenerSubscripcion(subscripciones, 'activo', false))

    const { data: emisor, isLoading: cargandoInformacion, isError, error } = useObtenerEmisorQuery({ id: cuentaFiscalID })
    const { data: logo, isLoading: cargandoLogo } = useObtenerLogoQuery({ usuarioID: usuario.id })

    useEffect(() => {
        const completoPerfil = (emisor = null) => {
            if(emisor) {
                return emisor?.razonsocial && emisor?.idfiscal && emisor?.codigopostal && emisor?.llave && emisor?.cetificado
            }
            else return false
        }

        const recuperarInfoDistribuidor = async (emisor) => {
            let data = await recuperarDistribuidorStripe(emisor.token_stripe)
            estadoCargandoPerfilStripe(false)

            if(data.ok) fijaPerfilStripe(data.proveedor)
            else fijaPerfilStripe({})
        }

        if(!cargandoInformacion) {
            if(cargandoPerfilStripe && getPermiso(CREAR_PERFIL_CONNECT)) {
                recuperarInfoDistribuidor(emisor)
            }
            else {
                estadoCargandoPerfilStripe(false)
                if(!completoPerfil(emisor)) {
                    ShowAlert({
                        icono: 'error',
                        titulo: 'Información NO Completada',
                        mensaje: 'Debes completar tu información para utilizar correctamente la página',
                        time: 3,
                    })
                }
                // else a completado sus datos
            }
        }
        // else esperando información emisor
    }, [ShowAlert, cargandoInformacion])

    if(cargandoInformacion || cargandoLogo || cargandoPerfilStripe) return
    else if(isError) {
        ShowAlert({
            icono: 'error',
            titulo: 'Sucedio un error al cargar tu información',
            mensaje: error.message,
        })
    }
    else {
        // console.log(subscripcionActiva)

        let botones = []
        if(getPermiso(CREAR_LINK)) {
            botones = [
                {
                    iconLeft: 'fa-link',
                    texto: 'Crear Link de Invitación',
                    onClick: (e) => { 
                        navigator.clipboard.writeText(
                            `${window.location.origin}?invitacion=${encodeURIComponent(AES.encrypt(String(infoUsuario().id), config.keySecretaEncriptar).toString())}`
                        )
                        .then(() => {
                            ShowAlert({
                                icono: 'success',
                                titulo: 'Enlace Creado',
                                mensaje: 'Tu enlace ahora puede ser compartido y así empezar a ganar comisones.',
                                time: 5,
                            })
                        })
                        .catch((error) => {
                            ShowAlert({
                                icono: 'error',
                                titulo: 'Error al Crear Enlace',
                                mensaje: error,
                                time: 5,
                            })
                        })
                    }
                }
            ]
        }
        else if(getPermiso(VER_ESTADO_SUBSCRIPCION)) {
            if(subscripcionActiva) {
                botones = [
                    {
                        iconLeft: 'fa-crown',
                        texto: 'Estado Subscripcion',
                        onClick: (e) => {
                            Push('estado_subscripcion', {
                                [FORM_ESTADO_SUBSCRIPCION]: { 
                                    ...subscripcionActiva 
                                }
                            })
                        }
                    }
                ]
            }
            // else no agregar boton
        }
        // else no agregar botones extra

        botones = [
            ...botones,
            {
                iconLeft: 'fa-pen',
                texto: 'Editar',
                onClick: (e) => {
                    Push('editar_perfil', {
                        [FORM_PERFIL]: { ...emisor },
                    })
                }
            },
            {
                iconLeft: 'fa-file-lines',
                texto: 'Descargar Clave Privada',
                onClick: async () => {
                    if(clavePrivada) {
                        abrirArchivo(
                            await obtenerURLObjeto(`${config.urlCRUFDEKArchivo}/${clavePrivada.carpeta}/${clavePrivada.nombre}`), 
                            'descargar', 
                            clavePrivada.nombre
                        )
                    }
                    else {
                        ShowAlert({
                            icono: 'error',
                            titulo: 'Archivo NO Disponible',
                            mensaje: 'Antes de descargar debes actualizar tu información',
                            time: 5,
                        })
                    }
                }
            },
            {
                iconLeft: 'fa-file-contract',
                texto: 'Descargar Certificado',
                onClick: async () => {
                    if(certificado) {
                        abrirArchivo(
                            await obtenerURLObjeto(`${config.urlCRUFDEKArchivo}/${certificado.carpeta}/${certificado.nombre}`), 
                            'descargar', 
                            certificado.nombre
                        )
                    }
                    else {
                        ShowAlert({
                            icono: 'error',
                            titulo: 'Archivo NO Disponible',
                            mensaje: 'Antes de descargar debes actualizar tu información',
                            time: 5,
                        })
                    }
                }
            },
            {
                iconLeft: 'fa-arrow-right-from-bracket',
                texto: 'Cerrar Sesión',
                onClick: (e) => { navegacion('/cerrar_sesion') }
            }
        ]



        return (
            <MiPerfil
                avatar={obtenerLogo(logo, nologo, 'logo')}
                nombre={emisor.razonsocial ?? 'Sin razón social'}
                correo={usuario.correo ?? 'Sin correo electronico'}
                telefono={emisor.telefono ?? 'Sin número telefonico'}
                extrasInfo={[
                    { titulo: 'RFC', contenido: emisor.idfiscal ?? 'Vacío' },
                    { titulo: 'Regimen Fiscal', contenido: obtenerValor(regimenesSociales, emisor.regimen, 'Vacío') },
                    { titulo: 'Código Postal', contenido: emisor.codigopostal ?? 'Vacío' },
                    { titulo: 'Cuenta con archivo .key', contenido: emisor.llave ? 'SI' : 'NO' },
                    { titulo: 'Cuenta con archivo .cer', contenido: emisor.cetificado ? 'SI' : 'NO' },
                    getPermiso(CREAR_PERFIL_CONNECT) ? { 
                        titulo: 'Tranferencias a Cuenta Bancaria', 
                        contenido: perfilStripe?.capabilities?.transfers === 'active' ? 'Habilitadas' : 'Deshabilitadas' 
                    } : {}
                ]}
                botones={botones}
            >
            </MiPerfil>
        )
    }
}

export default Perfil