import { configureStore } from '@reduxjs/toolkit'
import sesionReducer from '../feactures/sesion/SesionSlice'
import { usuarioAPI } from '../services/usuario'
import { emisorAPI } from '../services/emisor'
import { receptorAPI } from '../services/receptor';
import { conceptoAPI } from '../services/concepto';
import { facturaAPI } from '../services/factura';
import { subscripcionAPI } from '../services/subcripcion';
import { miembroAPI } from '../services/miembro';
import { membresiaAPI } from '../services/membresia';

export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        [usuarioAPI.reducerPath]: usuarioAPI.reducer,
        [emisorAPI.reducerPath]: emisorAPI.reducer,
        [receptorAPI.reducerPath]: receptorAPI.reducer,
        [conceptoAPI.reducerPath]: conceptoAPI.reducer,
        [facturaAPI.reducerPath]: facturaAPI.reducer,
        [subscripcionAPI.reducerPath]: subscripcionAPI.reducer,
        [miembroAPI.reducerPath]: miembroAPI.reducer,
        [membresiaAPI.reducerPath]: membresiaAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({serializableCheck: false}),
        usuarioAPI.middleware,
        emisorAPI.middleware,
        receptorAPI.middleware,
        conceptoAPI.middleware,
        facturaAPI.middleware,
        subscripcionAPI.middleware,
        miembroAPI.middleware,
        membresiaAPI.middleware,
    ],
});