import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_MIEMBRO = 'EurekaPaCuentaFiscal'

export const miembroAPI = createApi({
    reducerPath: 'miembroAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Miembros'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerMiembros: builder.query({
            query: () => {
                let body = new EkQuery(TABLA_MIEMBRO)
                return {
                    url: `/READ?key=${config.key}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                            .where('token_stripe', '!=', 'null')
                            .where('estatus', 'solicitud')
                            .with('eureka_ac_usuario')
                            .get()
                }
            }
        }),
    }),
})


export const { 
    useObtenerMiembrosQuery,
} = miembroAPI